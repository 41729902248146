import logo from '@assets/images/closed-sidebar-logo-dark.png';
import i18next from 'i18next';

export const USER_NAME = 'HumanMessage';
export const ASSISTANCE_NAME = 'AIMessage';
export const ASSISTANCE_AVATAR = logo;
export const WELCOME_MESSAGE = i18next.t('chatterly.welcomeMessage');

export const serializedMessages = (messages) =>
  messages.map((message) => {
    return { role: message.role, content: message.content };
  });
