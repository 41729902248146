import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserData } from '@redux/slices/user';
import { canAccessAsAdmin } from '@utils/validations';
import { authPaths } from '@routes/paths';

ReviewAdminGuard.propTypes = {
  children: PropTypes.node,
};

export default function ReviewAdminGuard({ children }) {
  const { user } = useSelector(selectUserData);
  const { haveAccessAsAdmin } = canAccessAsAdmin({ user });
  if (!haveAccessAsAdmin) return <Navigate to={authPaths.home} />;
  return <>{children}</>;
}
