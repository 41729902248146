// @import Dependencies
import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// @import Utils
import { nomenclatureSnack } from '@utils/nomenclature';
import { ASSISTANCE_NAME, WELCOME_MESSAGE } from '@utils/chat';
import { orderedListById } from '@utils/sortFilter';

// @import Services
import { chatReportAPI } from '@api/chat';

const chatAdapter = createEntityAdapter({});

const initialState = chatAdapter.getInitialState({
  ids: [0],
  entities: [
    {
      id: 0,
      role: ASSISTANCE_NAME,
      content: WELCOME_MESSAGE,
      icons: false,
      actions: null,
    },
  ],
  loading: false,
  quickAdd: null,
  fontSize: 15,
});

export const write = createAsyncThunk(
  'chat/write',
  async ({ shopId, body }, thunkAPI) => {
    thunkAPI.dispatch(
      sendMessage({
        role: ASSISTANCE_NAME,
        content: '',
        isWaitingMessage: true,
      })
    );
    const { data, isSuccessful, statusKey } = await chatReportAPI({
      body,
      shopId,
    });
    if (isSuccessful) return data;

    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder

    // Write
    .addCase(write.pending, (state) => {
      state.loading = true;
    })
    .addCase(write.rejected, (state, action) => {
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
      state.loading = false;
    })
    .addCase(write.fulfilled, (state, action) => {
      const [ai] = action.payload.messages;
      const isWaitingMessage =
        state.entities[state.ids.length - 1]?.role === ASSISTANCE_NAME;

      chatAdapter.upsertOne(state, {
        id: isWaitingMessage ? state.ids.length - 1 : state.ids.length,
        role: ai.role,
        content: ai.message,
        icons: true,
        actions: null,
        isWaitingMessage: false,
        streaming: true,
      });
      state.loading = false;
    });
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    sendMessage: (state, action) => {
      chatAdapter.upsertOne(state, {
        id: state.ids.length,
        ...action.payload,
      });
    },
    updateMessage: (state, action) => {
      chatAdapter.upsertOne(state, {
        id: action.payload.id || state.ids.length - 1,
        ...action.payload,
      });
    },
    resetChat: () => initialState,
    setQuickAdd: (state, action) => {
      state.quickAdd = action.payload;
    },
    disableStreaming: (state) => {
      if (!state.entities) return;
      chatAdapter?.updateMany(
        state,
        state?.entities?.map((message) => ({
          id: message.id,
          changes: {
            streaming: false,
          },
        }))
      );
    },
    setFontSize: (state, action) => {
      if (state.fontSize <= 15 && !action.payload) return;
      if (action.payload) state.fontSize += 1;
      else state.fontSize -= 1;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  sendMessage,
  updateMessage,
  resetChat,
  setQuickAdd,
  disableStreaming,
  setFontSize,
} = chatSlice.actions;
export default chatSlice.reducer;

// ------------------SELECTORS-------------
export const {
  selectAll: selectAllMessages,
  selectById: selectMessageById,
  selectIds: selectMessagesIds,
} = chatAdapter.getSelectors((state) => state.chat);

export const selectLoading = (state) => state.chat.loading;
export const selectQuickAdd = (state) => state.chat.quickAdd;
export const selectChatFontSize = (state) => state.chat.fontSize;
export const selectLastIAMessage = createSelector(
  selectAllMessages,
  (messages) =>
    orderedListById({
      list: messages,
    }).shift()
);
