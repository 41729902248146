// @import Dependencies
import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';
import { getShopifyAppUrl } from '@utils/getShopifyRedirect';

// @import Services
import {
  getCmsIntegrationsAPI,
  saveCmsConnectionAPI,
  saveCmsConnectionRedirectAPI,
  editCmsConnectionAPI,
  deleteCmsConnectionAPI,
  requestCmsConnectionAPI,
  getCmsProductsIntegrationByIdAPI,
  getCmsIntegrationCategoriesAPI,
  getCmsIntegrationCategoriesAndProductsAPI,
} from '@api/integration';

// @import Reducers
import { setShopDataAdded } from '@redux/slices/dashboard';

const initialLoadings = {
  loadingIntegrations: false,
  loadingConnectionChange: false,
  loadingCmsProducts: false,
  loadingCmsCategories: false,
};

export const initialState = {
  loadings: initialLoadings,
  selectedCmsInModal: null,
  integrations: [],
  cmsProducts: {},
  cmsCategories: {},
  cmsPullId: {},
  cmsPullData: {},
  assetsPagination: {},
  showIntegrationSuccessView: false,
  showIntegrationTryAgainView: false,
  ga4ConnectionSuccessView: false,
  newIntegrationStep: 1,
  semrush: {
    phrase_related: {
      loading: false,
      rows: [],
    },
    phrase_fullsearch: {
      loading: false,
      rows: [],
    },
  },
};

// ------------------THUNKS----------------
export const getCmsIntegrations = createAsyncThunk(
  'integration/getCmsIntegrations',
  async (_, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await getCmsIntegrationsAPI();
    if (isSuccessful) return res;
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const saveCmsConnection = createAsyncThunk(
  'integration/saveCmsConnection',
  async (data, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await saveCmsConnectionAPI(data);

    if (isSuccessful) {
      thunkAPI.dispatch(setShopDataAdded(res));

      if (data.is_shopify_app && data.ekomStoreName) {
        window.top.location.href = getShopifyAppUrl(
          data.ekomStoreName,
          'success'
        );
      }

      return { ...res, statusKey };
    }

    if (data.is_shopify_app && data.ekomStoreName) {
      window.top.location.href = getShopifyAppUrl(
        data.ekomStoreName,
        'errorcms'
      );
    }

    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const saveCmsConnectionRedirect = createAsyncThunk(
  'integration/saveCmsConnectionRedirect',
  async (data, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await saveCmsConnectionRedirectAPI(data);
    if (isSuccessful) {
      return { ...res, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const editCmsConnection = createAsyncThunk(
  'integration/editCmsConnection',
  async ({ data, id }, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await editCmsConnectionAPI({ body: data, id });
    if (isSuccessful) {
      return { ...res, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const deleteCmsConnection = createAsyncThunk(
  'integration/deleteCmsConnection',
  async (id, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await deleteCmsConnectionAPI(id);
    if (isSuccessful) {
      return { ...res, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const requestCmsConnection = createAsyncThunk(
  'integration/requestCmsConnection',
  async (data, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await requestCmsConnectionAPI(data);
    if (isSuccessful) {
      return { ...res, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const getCmsProductsIntegrationById = createAsyncThunk(
  'integration/getCmsProductsIntegrationById',
  async (body, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await getCmsProductsIntegrationByIdAPI(body);
    if (isSuccessful) {
      return { products: res, integration: body?.shopId, newPage: body?.page };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const getCmsIntegrationCategories = createAsyncThunk(
  'integration/getCmsIntegrationCategories',
  async (body, thunkAPI) => {
    const {
      data: res,
      isSuccessful,
      statusKey,
    } = await getCmsIntegrationCategoriesAPI(body);
    if (isSuccessful) {
      return {
        collections: res,
        integration: body?.shopId,
        newPage: body?.page,
      };
    }
    return thunkAPI.rejectWithValue({ ...res, statusKey });
  }
);

export const getCmsIntegrationCategoriesAndProducts = createAsyncThunk(
  'integration/getCmsIntegrationCategoriesAndProducts',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getCmsIntegrationCategoriesAndProductsAPI(body);
    if (isSuccessful) {
      return { ...data, integration: body?.id };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const sharedExtraReducers = (builder) => {
  builder
    .addCase(getCmsIntegrations.pending, (state) => {
      state.loadings = { ...state.loadings, loadingIntegrations: true };
    })
    .addCase(getCmsIntegrations.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingIntegrations: false };
      state.integrations = action.payload?.map((_i) => {
        return {
          ..._i,
          value: _i?.id,
          label: _i?.public_shop_url,
        };
      });
    })
    .addCase(getCmsIntegrations.rejected, (state, action) => {
      state.error = action.payload?.statusKey;
      state.loadings = { ...state.loadings, loadingIntegrations: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(saveCmsConnection.pending, (state) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: true };
    })
    .addCase(saveCmsConnection.fulfilled, (state, action) => {
      state.showIntegrationSuccessView = true;
      state.showIntegrationTryAgainView = false;
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(saveCmsConnection.rejected, (state, action) => {
      state.showIntegrationSuccessView = false;
      state.showIntegrationTryAgainView = true;
      state.error = action.payload?.statusKey;
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(saveCmsConnectionRedirect.pending, (state) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: true };
    })
    .addCase(saveCmsConnectionRedirect.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(saveCmsConnectionRedirect.rejected, (state, action) => {
      state.error = action.payload?.statusKey;
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(editCmsConnection.pending, (state) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: true };
    })
    .addCase(editCmsConnection.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(editCmsConnection.rejected, (state, action) => {
      state.error = action?.payload?.statusKey;
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(deleteCmsConnection.pending, (state) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: true };
    })
    .addCase(deleteCmsConnection.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(deleteCmsConnection.rejected, (state, action) => {
      state.error = action?.payload?.statusKey;
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(requestCmsConnection.pending, (state) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: true };
    })
    .addCase(requestCmsConnection.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(requestCmsConnection.rejected, (state, action) => {
      state.error = action.payload?.statusKey;
      state.loadings = { ...state.loadings, loadingConnectionChange: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getCmsProductsIntegrationById.pending, (state) => {
      state.loadings = { ...state.loadings, loadingCmsProducts: true };
    })
    .addCase(getCmsProductsIntegrationById.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingCmsProducts: false };
      const lastProducts = state.cmsProducts[action.payload?.integration] || [];
      state.cmsProducts[action.payload?.integration] = [
        ...lastProducts,
        ...action.payload.products,
      ];
      state.assetsPagination[action.payload?.integration] = {
        ...state.assetsPagination[action.payload?.integration],
        products: {
          currentPage: action.payload?.newPage,
          hasMore: action.payload?.products?.length >= 10,
        },
      };
    })
    .addCase(getCmsProductsIntegrationById.rejected, (state, action) => {
      state.loadings = { ...state.loadings, loadingCmsProducts: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getCmsIntegrationCategories.pending, (state) => {
      state.loadings = { ...state.loadings, loadingCmsCategories: true };
    })
    .addCase(getCmsIntegrationCategories.fulfilled, (state, action) => {
      state.loadings = { ...state.loadings, loadingCmsCategories: false };
      const lastCategories =
        state.cmsCategories[action.payload?.integration] || [];
      state.cmsCategories[action.payload?.integration] = [
        ...lastCategories,
        ...action.payload.collections,
      ];
      state.assetsPagination[action.payload?.integration] = {
        ...state.assetsPagination[action.payload?.integration],
        categories: {
          currentPage: action.payload?.newPage,
          hasMore: action.payload?.collections?.length >= 10,
        },
      };
    })
    .addCase(getCmsIntegrationCategories.rejected, (state, action) => {
      state.loadings = { ...state.loadings, loadingCmsCategories: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getCmsIntegrationCategoriesAndProducts.pending, (state) => {
      state.loadings = {
        ...state.loadings,
        loadingCmsCategories: true,
        loadingCmsProducts: true,
      };
    })
    .addCase(
      getCmsIntegrationCategoriesAndProducts.fulfilled,
      (state, action) => {
        state.loadings = {
          ...state.loadings,
          loadingCmsCategories: false,
          loadingCmsProducts: false,
        };
        const isAllCategories = action.meta?.arg?.type === 'getAllCategories';
        state.cmsCategories[action.payload?.integration] =
          action?.payload?.categories;
        state.cmsProducts[action.payload?.integration] =
          action.payload?.products;
        state.cmsPullId[action.payload?.integration] = action.payload?.pull_id;
        state.cmsPullData[action.payload?.integration] = action.payload;
        state.assetsPagination[action.payload?.integration] = {
          products: {
            currentPage: 1,
            hasMore: action.payload?.products?.length >= 10,
            products_count: action.payload?.products_count,
            products_count_draft: action.payload?.products_count_draft,
            products_count_published: action.payload?.products_count_published,
          },
          categories: {
            currentPage: 1,
            hasMore: isAllCategories
              ? false
              : action.payload?.categories?.length >= 10,
            categories_count: action.payload?.categories_count,
          },
        };
      }
    )
    .addCase(
      getCmsIntegrationCategoriesAndProducts.rejected,
      (state, action) => {
        state.loadings = {
          ...state.loadings,
          loadingCmsCategories: false,
          loadingCmsProducts: false,
        };
        if (action?.payload?.showSnackError) {
          nomenclatureSnack({
            type: 'error',
            message: action?.payload?.statusKey,
          });
        }
      }
    );
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setSelectedCmsInModal: (state, action) => {
      state.selectedCmsInModal = action.payload;
    },
    setIntegrationSuccessView: (state, action) => {
      state.showIntegrationSuccessView = action.payload;
    },
    setNewIntegrationStep: (state, action) => {
      state.newIntegrationStep = action.payload;
    },
    setIntegrationTryAgainView: (state, action) => {
      state.showIntegrationTryAgainView = action.payload;
    },
    setGA4ConnectionSuccessView: (state, action) => {
      state.ga4ConnectionSuccessView = action.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  setSelectedCmsInModal,
  setIntegrationSuccessView,
  setIntegrationTryAgainView,
  setNewIntegrationStep,
  setGA4ConnectionSuccessView,
} = integrationSlice.actions;
export default integrationSlice.reducer;

// ------------------SELECTORS-------------
export const selectSelectedCmsInModal = (state) =>
  state.integration.selectedCmsInModal;
const selectCmsProducts = (state, shopId) =>
  state.integration.cmsProducts[shopId];
const selectCmsPullData = (state, shopId) =>
  state.integration.cmsPullData[shopId];
const selectCmsCategories = (state, shopId) =>
  state.integration.cmsCategories[shopId];
const selectCmsIntegration = (state, id) =>
  state.integration.integrations.find((_i) => _i.id === id)?.integration_type;

export const selectCmsShopProducts = createSelector(
  selectCmsProducts,
  (shopId) => shopId
);
export const selectCmsShopPullData = createSelector(
  selectCmsPullData,
  (shopId) => shopId
);
export const selectCmsShopCategories = createSelector(
  selectCmsCategories,
  (shopId) => shopId
);
export const selectCmsIntegrationById = createSelector(
  selectCmsIntegration,
  (integration) => integration
);

export const selectIntegrationsLoadings = (state) => state.integration.loadings;

export const selectCMSIntegrations = (state) => state.integration.integrations;
export const selectActiveCMSIntegrations = (state) =>
  state.integration.integrations?.filter((_s) => _s?.active);

export const selectNewIntegrationStep = (state) =>
  state.integration.newIntegrationStep;

export const selectCMSDetailScreenLoading = (state) =>
  state.integration.automationDetailScreen.loading;

export const selectCmsIntegrationGrouped = createSelector(
  [(state) => state.integration.integrations],
  (integrations) => {
    return integrations.reduce((acc, item) => {
      const foundIntegration = acc.findIndex(
        (_i) => _i?.integration_type === item?.integration_type
      );
      if (foundIntegration === -1) {
        acc.push({ integration_type: item?.integration_type });
      }
      return acc;
    }, []);
  }
);
export const selectCmsShopPullIds = (state) => state.integration.cmsPullId;

export const selectCmsAssetsPagination = (state) =>
  state.integration.assetsPagination;

export const selectShowIntegrationSuccessView = (state) =>
  state.integration.showIntegrationSuccessView;

export const selectGA4ConnectionSuccessView = (state) =>
  state.integration.ga4ConnectionSuccessView;

export const selectShowIntegrationTryAgainView = (state) =>
  state.integration.showIntegrationTryAgainView;
