import { makeRequest } from '@api/client';

const chatReportAPI = async ({ body, shopId }) => {
  return await makeRequest(`post_seo_chatbot_message/${shopId}`, {
    method: 'POST',
    body,
  });
};

export { chatReportAPI };
