// @import Dependencies
import i18n from '@translation/i18n';
// @import Components
import { Box, Typography } from '@mui/material';
// @import Utils
import { capitalizeWord } from '@utils/validations';

export const renderLabel = ({ textContent, theme }) => {
  return (
    <Box className="flex justify-start items-center max-h-12">
      <Typography
        className="items-center font-bold"
        variant="h5"
        style={{ color: theme.palette.text.secondary }}
      >
        {textContent}
      </Typography>
    </Box>
  );
};

export const renderSectionHeader = ({
  label,
  extraButtons,
  theme,
  renderTitle,
}) => {
  return (
    <Box>
      <Box className="flex flex-row gap-20 w-full h-fit pb-2 items-end justify-between rounded-[10px]">
        {renderTitle && (
          <Typography variant="h6" className="uppercase font-bold h-fit" noWrap>
            {label}
          </Typography>
        )}
        {extraButtons}
      </Box>
      <div
        className="h-[2px] w-full rounded-[10px]"
        style={{
          backgroundColor: theme.palette.dashboard.activityLog.borderDivider,
        }}
      />
    </Box>
  );
};

export const getProductStatusLabel = ({
  autopilotJob,
  autopilotPushed,
  enabled,
}) => {
  const statusMappings = [
    {
      condition: ({ job, pushed, enabled }) =>
        job !== null && pushed && enabled,
      label: i18n.t('dashboard.productListing.status.optimized'),
    },
    {
      condition: ({ job, pushed, enabled }) =>
        job !== null && !pushed && enabled,
      label: i18n.t('dashboard.productListing.status.reviewOptimization'),
    },
    {
      condition: ({ job, enabled }) => job === null && enabled,
      label: i18n.t('dashboard.productListing.status.upNext'),
    },
    {
      condition: ({ enabled }) => !enabled,
      label: i18n.t('dashboard.productListing.status.enable'),
    },
  ];

  let statusLabel = '';

  for (const mapping of statusMappings) {
    if (
      mapping.condition({ job: autopilotJob, pushed: autopilotPushed, enabled })
    ) {
      statusLabel = mapping.label;
      break;
    }
  }

  return statusLabel;
};

export function getRandomColor() {
  // Generate random values for red, green, and blue components
  const red = Math.floor(Math.random() * 256); // 0 to 255
  const green = Math.floor(Math.random() * 256); // 0 to 255
  const blue = Math.floor(Math.random() * 256); // 0 to 255

  // Construct and return the RGB color string
  const color = `rgb(${red}, ${green}, ${blue})`;
  return color;
}

export const getOuterBackgroundColors = (length) => {
  const firstOuterColors = [
    '#0BBAD2',
    '#135C30',
    '#0A1AA9',
    '#BAF512',
    '#7959FA',
    '#2ECB71',
  ];

  // Generate additional random colors if needed
  const additionalColors = Array.from(
    { length: Math.max(0, length - firstOuterColors.length) },
    () => getRandomColor()
  );

  // Concatenate the firstOuterColors and additionalColors arrays
  const outerColors = firstOuterColors
    .slice(0, length)
    .concat(additionalColors);

  return outerColors;
};

export const formatLabel = (context) => {
  let word = context.dataset.labels[context.dataIndex] || '';
  word = word.length > 18 ? `${word.slice(0, 18)}..` : word;
  return `${capitalizeWord(word)}: ${context.parsed}`;
};

export const innerColors = ['#CB4A2E', '#000000'];

export const viewMetricsParams = {
  '1W': {
    interval: 'w',
  },
  '1M': {
    interval: '1m',
  },
  '3M': {
    interval: '3m',
  },
};

export const defaultMetricParams = viewMetricsParams['1M'];
