export const getShopifyAppUrl = (
  storeName,
  statusPath,
  appName = import.meta.env.VITE_SHOPIFY_APP_NAME
) => {
  if (!storeName) throw new Error('storeName is required.');
  if (!statusPath)
    throw new Error('statusPath is required. (e.g. "success" or "errorcms")');

  const shopifyRedirectApp = import.meta.env.VITE_SHOPIFY_REDIRECT_APP;

  return `${shopifyRedirectApp}/${storeName}/apps/${appName}/app/${statusPath}`;
};
