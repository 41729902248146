export default function InputBase(theme) {
  return {
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState }) => {
          // Outlined
          if (ownerState.variant === 'outlined') {
            return {
              backgroundColor: 'rgba(1, 0, 0, 0) !important',
              fontSize: '14px',
              margin: '0px !important',
              padding: '2px 10px !important',
              borderRadius: 8,
              border: `1px solid ${theme.palette.text.primary}`,
              '::before': {
                borderBottom: `0px !important`,
              },
              '::after': {
                borderBottom: `0px !important`,
              },
            };
          }
          // Default
          return {
            '::before': {
              borderBottom: `1px solid ${theme.palette.text.secondary}!important`,
            },
            '::after': {
              borderBottom: `1px solid ${theme.palette.text.primary}!important`,
            },
          };
        },
        input: {},
        error: ({ ownerState }) => {
          // Outlined
          if (ownerState.variant === 'outlined') {
            return {
              color: `${theme.palette.various.errorPlaceholder} !important`,
              border: `1px solid ${theme.palette.various.errorMessages} !important`,
              '& .MuiInputBase-input::placeholder': {
                color: `${theme.palette.various.errorMessages} !important`,
              },
            };
          }
          // Default
          return {
            color: theme.palette.various.errorPlaceholder,
            '::before': {
              borderBottom: `1px solid ${theme.palette.various.errorMessages}!important`,
            },
            '::after': {
              borderBottom: `1px solid ${theme.palette.various.errorMessages}!important`,
            },
          };
        },
      },
    },
  };
}
