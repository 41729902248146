// @import Dependencies
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @import Selectors
import { authPaths } from '@routes/paths';
import { selectUserData } from '@redux/slices/user';
import { isDemoAccount } from '@utils/validations';

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { loading, user } = useSelector(selectUserData);
  const [loaded, setLoaded] = useState(false);
  const { state } = useLocation();

  useEffect(() => {
    if (!loading) setLoaded(true);
  }, [loading]);

  const isAuthenticated = localStorage.getItem('accessToken') !== null;

  if (!isAuthenticated) return <Navigate to={authPaths.root} />;
  if (isAuthenticated && isDemoAccount(user) && !loaded) {
    const route =
      state?.prevState === '/' ? authPaths.onboarding : authPaths.optimizeLog;
    return <Navigate to={route} />;
  }

  if (loaded) return <>{children}</>;
}
