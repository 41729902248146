import { GREY, COMMON, PRIMARY } from '@theme/modes/common';
import { LIGHT } from '@theme/shadows';

const styles = {
  ...COMMON,
  mode: 'light',
  text: {
    primary: GREY[800],
    secondary: '#000',
    disabled: GREY[500],
    neutral: GREY[800],
    alternative: '#FFFA77',
    contrastText: '#fff',
    alternativeContrastText: '#000',
    link: '#000',
    customTitle: '#5B5666',
  },
  disableButtonText: 'rgba(131, 131, 131, 1)',
  alternative: '#D8D465',
  sidebar: {
    background: '#C5CBD366',
    border: '#C5CBD3',
    borderDivider: '#C5CBD3',
    icon: {
      default: '#000',
      active: '#FFFA77',
      background: '#f0f2f5',
      backgroundActive: '#FFFA7726',
    },
  },
  optimizationLog: {
    rowRevertion:
      'linear-gradient(135deg, rgba(255, 250, 119, 0.15) 0%, #FFFA77 100%)',
    approvedSection: {
      headerBackground: '#077655',
    },
  },
  auth: {
    input: 'rgba(20, 20, 20, 0.09)',
  },
  datePicker: {
    border: GREY[500_24],
  },
  background: { paper: '#FDFAF7', default: '#FDFAF7', neutral: GREY[100] },
  table: {
    background: {
      default: '#f0f2f5',
      header: '#FDFAF7',
    },
    border: GREY[500_24],
    primary: '#FDFAF7',
    stickyHeader: '#FDFAF7',
    alternative: '#93464E',
    iconButtonBackground: '#000',
  },
  shadows: LIGHT,
  action: { active: GREY[600], ...COMMON.action },
  account: {
    backgound: '#f0f2f5',
    header: '#C5CBD366',
  },
  cards: {
    backgrounds: {
      primary: 'transparent',
      secondary: 'transparent',
    },
    shadows: {
      primary: 'transparent',
      secondary: 'transparent',
    },
    border: GREY[500],
    skeletonBorder: GREY[500],
    planRailBackground: '#FFFA77',
    subscriptionBackground: '#FFFFFF',
    cardPrice: '#FFFA77',
    checkGradient: {
      from: '#FFFA77',
      to: '#FFFA77',
    },
    integrationBackground: '#f5f3f0',
    integrationText: '#000',
    settings: {
      background: '#FFFFFF',
      shadow: LIGHT[6],
    },
    members: {
      background: '#FFFFFF',
      shadow: LIGHT[6],
    },
    optimize: {
      notPushed: '#942430',
      pushed: '#FFFA77',
    },
    assetsBackground: 'transparent',
    plan: {
      essential: {
        background: '#FDFAF7',
        text: GREY[800],
        border: GREY[400],
      },
      growth: {
        background: '#FDFAF7',
        text: GREY[800],
        border: GREY[400],
      },
      enterprise: {
        background: GREY[800],
        text: '#FDFAF7',
        border: GREY[400],
      },
    },
  },
  switch: {
    active: '#FFFA77',
    inactive: '#FFFA7782',
    theme: {
      text: '#FFF',
      checked: GREY[0],
      trackBackground: '#B9B9B9',
      background: '#FFFA77',
    },
  },
  dropdowns: {
    secondary: '#fff',
    border: '#000',
    darker: '#000',
    text: GREY[800],
    indicatorColor: GREY[800],
    parentContainer: '#fff',
    childContainer: '#e7e7e8',
    singleOptionBackground: '#fff',
    multiOptionBackground: '#fff',
    checkBox: '#FFFA77',
    member: {
      backgroundSelector: '#fff',
      backgroundOptions: '#fff',
      border: '#1c1c1c',
    },
    storeVariant: {
      text: '#000',
      backgroundIconHover: '#fdfaf7',
    },
    defaultIconBackground: '#919352',
  },
  loader: {
    spinner: {
      border: PRIMARY['main'],
      shadow: PRIMARY['dark'],
      shadowContrast: '#fff',
      background: '#ffffffa3',
    },
  },
  various: {
    buttonOptions: '#FF4E8D',
    matchText: '#FFFA77',
    newText: '#317878',
    dashboardsCardsText: '#000',
    softIcons: GREY[600],
    divider: '#DFDFDF',
    errorPlaceholder: '#F10000',
    errorMessages: '#F10000',
    badgeCounter: '#D42C9B',
    emailButton: '#e0dddc',
  },
  icon: {
    primary: '#FFFA77',
    questionMark: '#ABB1B3',
  },
  dashboard: {
    activityLog: {
      borderDivider: '#FFFA77',
      itemBackground: '#f0f0f0',
    },
    graphic: {
      subtitle: '#000',
      containerBg: '#fff',
      gridColor: '#FFFA7726',
      optimized: '#943432',
      nonOptimized: '#434C4C',
      sessions: '#C9412633',
      conversions: '#888888',
      filter: {
        optionSelected: '#FFFA77',
        option: '#fff',
      },
    },
    navbar: {
      stickyBackground: '#E7E7E7',
    },
  },
  keywords: {
    input: '#ece9eb',
    button: '#C1B6D699',
    buttonModal: '#DEDEFF1C',
  },
  checkBox: {
    border: '#C5CBD3',
    background: '#FFFA77',
    icon: '#000',
  },
  buttons: {
    background: '#FFFA77',
    text: '#000',
    backgroundSecondary: '#942430',
    border: '#C5CBD3',
    borderSecondary: '#942430',
  },
  logoutButton: {
    background: '#FDFAF7',
    text: '#000',
  },
  GSCButton: {
    backgroundCard: '#FDFAF7',
    backgroundButton: '#FDFAF7',
  },
  stepper: {
    disabled: '#ece9eb',
    dividerBorder: '#942430',
  },
  permissionsDenied: {
    gradient: {
      from: '#942430',
      to: '#FFFA77',
    },
    denied: '#C64865',
  },
  successsView: {
    borderContainer: '#FFFFFF4D',
    iconBorder: '#000',
    backgroundContainer: '#FFFFFF4D',
  },
  cmsIntregrations: {
    backgroundLogo: 'transparent',
    borderLogo: '#fff',
    borderSelectedLogo: '#000',
    backgroundSelectedLogo: '#8C83C333',
  },
  dialog: {
    background: '#dce0e585',
    backgroundContainer: '#fff',
    resultBg: '#FDFAF7',
  },
  metrics: {
    card: {
      background: {
        default: 'transparent',
        main: '#f0f2f5',
        secondary: '#fdfaf7',
      },
    },
  },
  snackBar: {
    background: {
      success: '#fff',
      error: '#fff',
    },
  },
  subscription: {
    alternative: '#FFFA77',
  },
  brandGuideline: {
    background: '#FFFFFF',
  },
  slider: {
    background: '#FFFA77',
    border: '#942430',
  },
  textEditor: {
    outputBackground: '#e2d1d5',
    delimiter: 'FFFA77',
    attribute: '317878',
    attributeValue: 'AD5555',
  },
  cmsIcons: COMMON.cmsIcons,
  moderationCard: {
    iconBackground: '#e9e6e3',
    containerBackground: '#e9e6e3',
    iconContainerBackground: '#FDFAF7',
    status: {
      success: '#3DC292',
      error: '#FF4F4F',
      warning: '#FF994F',
    },
  },
  onboardingDynamic: {
    rulesContainerBg: '#ece9eb',
    rulesContainerSettingsBg: '#ece9eb',
    rulesBg: '#fdfaf7',
    gscCardBg: '#ece9eb',
  },
  tinymce: {
    background: '#ece9eb',
    backgroundHeader: '#e2d1d5',
    html: '#FFFA77',
    variables: '#317878',
    values: '#AD5555',
  },
  feedback: {
    background: '#ece9eb',
    inputBackground: '#5a4d53',
  },
  skeleton: {
    background: '#e7e7e8',
  },
  textField: {
    border: '#38505B',
    focusedBorder: '#FFFFFF',
    asterik: '#DD7380',
  },
  onboarding: {
    text: '#000000',
    highlight: '#C8C8C8',
    containerBg: '#fff',
    successBg: '#ece9eb',
    mostPopularBg: '#747474',
    mostPopular: '#fff',
    nextButton: '#fff',
    nextButtonBg: '#000',
    stepperBg: '#000000',
    buttonBlue: '#0F62FE',
    // Unused (old stepper)
    cardBg: '#952431',
    cardBorder: '#952431',
    progressBg: '#FFFA77',
    buttonText: '#000',
    buttonBorder: '#828282',
    buttonDisabled: '#FFFA7726',
    disabledCardBg: '#f4e9ea',
    disabledCardBorder: '#f4e9ea',
    disabledCardText: '#000',
  },
  billing: {
    text: '#697386',
  },
  tag: {
    active: '#077655',
    inactive: '#444B4A',
    invited: '#fff',
  },
  chat: {
    bubble: {
      background: '#919352',
    },
    background: { container: '#91935240', ai: '#DCE9FA', user: '#DCE9FA' },
    textColor: { ai: GREY[800], user: GREY[800] },
    actionColor: GREY[600],
  },
};
export default styles;
