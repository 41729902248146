import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  addPaymentMethodAPI,
  cancelAllSubscriptionAPI,
  cancelSubscriptionAPI,
  createShopifySubscriptionAPI,
  createSubscriptionAPI,
  deletePaymentMethodAPI,
  getCustomerPortalLink,
  getCustomerPortalLinkCheckout,
  getPaymentMethodsAPI,
  getPlansAPI,
  getPlansBracketAPI,
  getSubscriptionsAPI,
  pauseSubscriptionAPI,
  renewSubscriptionAPI,
  updatePaymentMethodAPI,
  updateSubscriptionAPI,
} from '@api/stripe';
import {
  userReportBug,
  userRequestIntegration,
  userRequestUpgrade,
} from '@api/user';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';
import { orderedListById } from '@utils/sortFilter';
import { isDemoAccount } from '@utils/validations';

// @import Paths
import { authPaths } from '@routes/paths';
// @import Reducers
import { setOnboardingPlanRecommended } from '@redux/slices/user';
// @import Data
import mockResponseData from '@data/usage-sample.json';
import sidebarData from '@data/sidebar-data.json';

export const initialState = {
  sideBarOpen: true,
  sideBarItemsClicked: [],
  sideBarMenu: sidebarData,
  sideBarCounters: {
    optimizeAssets: 0,
  },
  hiddenSideBarIcons: false,
  regenerateAllRejectedModal: false,
  pushAllItemsToCMSModal: false,
  reportBugModal: false,
  inviteMemberModal: false,
  inviteMemberSentModal: false,
  deleteMemberModal: false,
  changePasswordModal: false,
  cancelSubscriptionModal: false,
  addPaymentMethodModal: false,
  requestPricingModal: false,
  mobileMenuOpen: false,
  accessDeniedModal: false,
  reIndexingModal: false,
  requestUpgradeModal: false,
  requestIntegrationModal: false,
  accessKey: '',
  pushToCMSModal: false,
  pushToCMSConfirmationModal: false,
  setupAutomaticIndexingModal: false,
  integrationConnectedModal: false,
  brandGuidelinesUploadModal: false,
  activityLogModalOpen: false,
  undoContentUpdateModalOpen: false,
  bulkJobTab: 1,
  autopilotJobTab: 1,
  loading: false,
  onboarding: {
    noContentPushed: false,
    shopId: null,
  },
  keyImprovementsModal: false,
  stripeLoaders: {
    createPaymentMethodLoading: false,
    deletePaymentMethodLoading: false,
    getPaymentMethodLoading: false,
    updatePaymentMethodLoading: false,
    createSubscriptionLoading: false,
    getSubscriptionLoading: false,
    updateSubscriptionLoading: false,
    cancelSubscriptionLoading: false,
    pauseSubscriptionLoading: false,
    renewSubscriptionLoading: false,
    createStripeShopifySubscriptionLoading: false,
  },
  confirmShopifyPlanCancelModal: false,
  avatarUploaderModalOpenFor: false,
};

export const goToStripeCustomerPortal = createAsyncThunk(
  'navigation/goToStripeCustomerPortal',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getCustomerPortalLink(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const reportBug = createAsyncThunk(
  'navigation/reportBug',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await userReportBug(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const requestUpgrade = createAsyncThunk(
  'navigation/requestUpgrade',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await userRequestUpgrade(body);
    if (isSuccessful) {
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const requestIntegration = createAsyncThunk(
  'navigation/requestIntegration',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await userRequestIntegration(
      body
    );
    if (isSuccessful) return { ...data, statusKey };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const goToCustomerPortalLinkCheckout = createAsyncThunk(
  'navigation/goToCustomerPortalLinkCheckout',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getCustomerPortalLinkCheckout(body);
    if (isSuccessful) {
      window.top.location.href = data.portal_url;
      return { ...data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getStripePlans = createAsyncThunk(
  'navigation/getStripePlans',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getPlansAPI();
    if (isSuccessful) {
      return { data, statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getStripePlansBracket = createAsyncThunk(
  'navigation/getStripePlansBracket',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getPlansBracketAPI();
    if (isSuccessful) {
      return { plans: data?.plans ?? [], statusKey };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getStripePaymentMethod = createAsyncThunk(
  'navigation/getStripePaymentMethod',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getPaymentMethodsAPI(body);
    if (isSuccessful) {
      if (!body?.notSetDefault) {
        thunkAPI.dispatch(
          setOnboardingPlanRecommended({
            paymentMethod: data?.find((pm) => pm.is_default)?.id,
          })
        );
      }
      if (isDemoAccount(thunkAPI.getState()?.user?.data))
        return {
          data: mockResponseData.paymentMethods,
          statusKey,
          isSuccessful,
        };

      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const addStripePaymentMethod = createAsyncThunk(
  'navigation/addStripePaymentMethod',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await addPaymentMethodAPI(body);
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const updateStripePaymentMethod = createAsyncThunk(
  'navigation/updateStripePaymentMethod',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updatePaymentMethodAPI(
      body
    );
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const deleteStripePaymentMethod = createAsyncThunk(
  'navigation/deleteStripePaymentMethod',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await deletePaymentMethodAPI(
      body
    );
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const createStripeSubscription = createAsyncThunk(
  'navigation/createStripeSubscription',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await createSubscriptionAPI(body);
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getStripeSubscription = createAsyncThunk(
  'navigation/getStripeSubscription',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getSubscriptionsAPI(body);
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const updateStripeSubscription = createAsyncThunk(
  'navigation/updateStripeSubscription',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateSubscriptionAPI(body);
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const cancelStripeSubscription = createAsyncThunk(
  'navigation/cancelStripeSubscription',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await cancelSubscriptionAPI(body);
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const cancelAllStripeSubscription = createAsyncThunk(
  'navigation/cancelAllStripeSubscription',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await cancelAllSubscriptionAPI(
      body
    );
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const pauseStripeSubscription = createAsyncThunk(
  'navigation/pauseStripeSubscription',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await pauseSubscriptionAPI(body);
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const renewStripeSubscription = createAsyncThunk(
  'navigation/renewStripeSubscription',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await renewSubscriptionAPI(body);
    if (isSuccessful) {
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const createStripeShopifySubscription = createAsyncThunk(
  'navigation/createStripeShopifySubscription',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await createShopifySubscriptionAPI(body);
    if (isSuccessful) {
      window.top.location.href = data.redirection_url;
      return { data, statusKey, isSuccessful };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

// ------------------THUNKS-------------
export const sharedExtraReducers = (builder) => {
  builder
    .addCase(goToStripeCustomerPortal.pending, (state) => {
      state.loading = true;
    })
    .addCase(goToStripeCustomerPortal.fulfilled, (state, action) => {
      const data = action.payload;
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: data?.statusKey,
      });
      if (data?.portal_url) {
        window.location.href = action.payload?.portal_url;
      } else {
        window.history.pushState(null, '', authPaths.subscriptionPortal);
        window.location.reload();
      }
    })
    .addCase(goToStripeCustomerPortal.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })

    // Report Bug
    .addCase(reportBug.pending, (state) => {
      state.loading = true;
    })
    .addCase(reportBug.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(reportBug.fulfilled, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })

    .addCase(requestUpgrade.pending, (state) => {
      state.loading = true;
    })
    .addCase(requestUpgrade.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(requestUpgrade.fulfilled, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })

    .addCase(requestIntegration.pending, (state) => {
      state.loading = true;
    })
    .addCase(requestIntegration.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(requestIntegration.fulfilled, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(goToCustomerPortalLinkCheckout.pending, (state) => {
      state.loading = true;
    })
    .addCase(goToCustomerPortalLinkCheckout.fulfilled, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
      setTimeout(() => {
        window.location.href = action.payload?.portal_url;
      }, 2000);
    })
    .addCase(goToCustomerPortalLinkCheckout.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getStripePlans.pending, (state) => {
      state.loading = true;
    })
    .addCase(getStripePlans.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(getStripePlans.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getStripePlansBracket.pending, (state) => {
      state.loading = true;
    })
    .addCase(getStripePlansBracket.fulfilled, (state) => {
      state.loading = false;
    })
    .addCase(getStripePlansBracket.rejected, (state, action) => {
      state.loading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getStripePaymentMethod.pending, (state) => {
      state.stripeLoaders.getPaymentMethodLoading = true;
    })
    .addCase(getStripePaymentMethod.fulfilled, (state) => {
      state.stripeLoaders.getPaymentMethodLoading = false;
    })
    .addCase(getStripePaymentMethod.rejected, (state, action) => {
      state.stripeLoaders.getPaymentMethodLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(addStripePaymentMethod.pending, (state) => {
      state.stripeLoaders.createPaymentMethodLoading = true;
    })
    .addCase(addStripePaymentMethod.fulfilled, (state) => {
      state.stripeLoaders.createPaymentMethodLoading = false;
    })
    .addCase(addStripePaymentMethod.rejected, (state, action) => {
      state.stripeLoaders.createPaymentMethodLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateStripePaymentMethod.pending, (state) => {
      state.stripeLoaders.updatePaymentMethodLoading = true;
    })
    .addCase(updateStripePaymentMethod.fulfilled, (state) => {
      state.stripeLoaders.updatePaymentMethodLoading = false;
    })
    .addCase(updateStripePaymentMethod.rejected, (state, action) => {
      state.stripeLoaders.updatePaymentMethodLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(deleteStripePaymentMethod.pending, (state) => {
      state.stripeLoaders.deletePaymentMethodLoading = true;
    })
    .addCase(deleteStripePaymentMethod.fulfilled, (state) => {
      state.stripeLoaders.deletePaymentMethodLoading = false;
    })
    .addCase(deleteStripePaymentMethod.rejected, (state, action) => {
      state.stripeLoaders.deletePaymentMethodLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(createStripeSubscription.pending, (state) => {
      state.stripeLoaders.createSubscriptionLoading = true;
    })
    .addCase(createStripeSubscription.fulfilled, (state) => {
      state.stripeLoaders.createSubscriptionLoading = false;
    })
    .addCase(createStripeSubscription.rejected, (state, action) => {
      state.stripeLoaders.createSubscriptionLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getStripeSubscription.pending, (state) => {
      state.stripeLoaders.getSubscriptionLoading = true;
    })
    .addCase(getStripeSubscription.fulfilled, (state) => {
      state.stripeLoaders.getSubscriptionLoading = false;
    })
    .addCase(getStripeSubscription.rejected, (state, action) => {
      state.stripeLoaders.getSubscriptionLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateStripeSubscription.pending, (state) => {
      state.stripeLoaders.updateSubscriptionLoading = true;
    })
    .addCase(updateStripeSubscription.fulfilled, (state) => {
      state.stripeLoaders.updateSubscriptionLoading = false;
    })
    .addCase(updateStripeSubscription.rejected, (state, action) => {
      state.stripeLoaders.updateSubscriptionLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(cancelStripeSubscription.pending, (state) => {
      state.stripeLoaders.cancelSubscriptionLoading = true;
    })
    .addCase(cancelStripeSubscription.fulfilled, (state) => {
      state.stripeLoaders.cancelSubscriptionLoading = false;
    })
    .addCase(cancelStripeSubscription.rejected, (state, action) => {
      state.stripeLoaders.cancelSubscriptionLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(cancelAllStripeSubscription.pending, (state) => {
      state.stripeLoaders.cancelSubscriptionLoading = true;
    })
    .addCase(cancelAllStripeSubscription.fulfilled, (state) => {
      state.stripeLoaders.cancelSubscriptionLoading = false;
    })
    .addCase(cancelAllStripeSubscription.rejected, (state, action) => {
      state.stripeLoaders.cancelSubscriptionLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(pauseStripeSubscription.pending, (state) => {
      state.stripeLoaders.pauseSubscriptionLoading = true;
    })
    .addCase(pauseStripeSubscription.fulfilled, (state) => {
      state.stripeLoaders.pauseSubscriptionLoading = false;
    })
    .addCase(pauseStripeSubscription.rejected, (state, action) => {
      state.stripeLoaders.pauseSubscriptionLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(renewStripeSubscription.pending, (state) => {
      state.stripeLoaders.renewSubscriptionLoading = true;
    })
    .addCase(renewStripeSubscription.fulfilled, (state) => {
      state.stripeLoaders.renewSubscriptionLoading = false;
    })
    .addCase(renewStripeSubscription.rejected, (state, action) => {
      state.stripeLoaders.renewSubscriptionLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(createStripeShopifySubscription.pending, (state) => {
      state.stripeLoaders.createStripeShopifySubscriptionLoading = true;
    })
    .addCase(createStripeShopifySubscription.fulfilled, (state) => {
      state.stripeLoaders.createStripeShopifySubscriptionLoading = false;
    })
    .addCase(createStripeShopifySubscription.rejected, (state, action) => {
      state.stripeLoaders.createStripeShopifySubscriptionLoading = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    });
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    toggleSideBar: (state, action) => {
      state.sideBarOpen = action.payload;
      if (!state.sideBarOpen) {
        state.sideBarItemsClicked = [];
      }
    },
    toggleSideBarItem: (state, action) => {
      const { id } = action.payload;
      state.sideBarItemsClicked = state.sideBarItemsClicked.find(
        (item) => item === id
      )
        ? [...state.sideBarItemsClicked].filter((item) => item !== id)
        : [...state.sideBarItemsClicked, id];
    },
    toggleRegenerateAllRejectedModal: (state, action) => {
      state.regenerateAllRejectedModal = action.payload;
    },
    togglePushAllItemsToCMSModal: (state, action) => {
      state.pushAllItemsToCMSModal = action.payload;
    },
    toggleInviteMemberModal: (state, action) => {
      state.inviteMemberModal = action.payload;
    },
    toggleInviteMemberSentModal: (state, action) => {
      state.inviteMemberSentModal = action.payload;
    },
    toggleDeleteMemberModal: (state, action) => {
      state.deleteMemberModal = action.payload;
    },
    toggleReIndexingModal: (state, action) => {
      state.reIndexingModal = action.payload;
    },
    toggleReportBugModal: (state, action) => {
      state.reportBugModal = action.payload;
    },
    toggleChangePasswordModal: (state, action) => {
      state.changePasswordModal = action.payload;
    },
    toggleCancelSubscriptionModal: (state, action) => {
      state.cancelSubscriptionModal = action.payload;
    },
    toggleAddPaymentMethodModal: (state, action) => {
      state.addPaymentMethodModal = action.payload;
    },
    toggleRequestPricingModal: (state, action) => {
      state.requestPricingModal = action.payload;
    },
    toggleMobileMenuOpen: (state, action) => {
      state.mobileMenuOpen = action.payload;
    },
    toggleAccessDeniedModal: (state, action) => {
      const { titleKey, isVisible } = action.payload;
      if (titleKey) state.accessKey = titleKey;
      state.accessDeniedModal = isVisible;
    },
    toggleRequestUpgradeModal: (state, action) => {
      state.requestUpgradeModal = action.payload;
    },
    toggleRequestIntegrationModal: (state, action) => {
      state.requestIntegrationModal = action.payload;
    },
    setAccessKey: (state, action) => {
      state.accessKey = action.payload;
    },
    togglePushToCMSModal: (state, action) => {
      state.pushToCMSModal = action.payload;
    },
    togglePushToCMSConfirmationModal: (state, action) => {
      state.pushToCMSConfirmationModal = action.payload;
    },
    toggleSetupAutomaticIndexingModal: (state, action) => {
      state.setupAutomaticIndexingModal = action.payload;
    },
    toggleBrandGuidelinesUploadModal: (state, action) => {
      state.brandGuidelinesUploadModal = action.payload;
    },
    toggleIntegrationConnectedModal: (state, action) => {
      state.integrationConnectedModal = action.payload;
    },
    toggleActivityLogModal: (state, action) => {
      state.activityLogModalOpen = action.payload;
    },
    toggleUndoContentUpdateModal: (state, action) => {
      state.undoContentUpdateModalOpen = action.payload;
    },
    toggleKeyImprovementsModal: (state, action) => {
      state.keyImprovementsModal = action.payload;
    },
    setBulkJobTab: (state, action) => {
      state.bulkJobTab = action.payload;
    },
    setAutopilotJobTab: (state, action) => {
      state.autopilotJobTab = action.payload;
    },
    setOptimizeAssetsCounter: (state, action) => {
      let jobs = action?.payload?.jobs;
      let counter = 0;
      if (action?.payload?.isGSC && Array.isArray(jobs) && jobs.length) {
        jobs = jobs.filter(
          (_j) => _j?.state === 'completed' && !_j.marked_as_trash
        );
        counter = jobs.length || 0;
      }
      state.sideBarCounters.optimizeAssets = counter;
    },
    decreaseOptimizeAssetsCounter: (state, action) => {
      const job = action?.payload;
      if (job && job?.state !== 'sent') {
        state.sideBarCounters.optimizeAssets -= 1;
      }
    },
    setOnboardingData: (state, action) => {
      const shops = action?.payload;
      if (shops && shops.length) {
        const shop = orderedListById({ list: [...shops], asc: false })[0];
        if (shop?.confetti) state.onboarding.noContentPushed = false;
        else {
          state.onboarding.shopId = shop?.id;
        }
      } else {
        state.onboarding.noContentPushed = true;
      }
    },
    setNoContentPushed: (state, action) => {
      state.onboarding.noContentPushed = action?.payload;
    },
    setHiddenSideBarIcons: (state, action) => {
      state.hiddenSideBarIcons = action?.payload;
    },
    toggleConfirmShopifyPlanCancelModal: (state, action) => {
      state.confirmShopifyPlanCancelModal = action.payload;
    },
    setAvatarUploaderModalOpenFor: (state, action) => {
      state.avatarUploaderModalOpenFor = action.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  toggleSideBar,
  toggleSideBarItem,
  toggleRegenerateAllRejectedModal,
  togglePushAllItemsToCMSModal,
  toggleInviteMemberModal,
  toggleInviteMemberSentModal,
  toggleDeleteMemberModal,
  togglePushToCMSConfirmationModal,
  toggleSetupAutomaticIndexingModal,
  toggleReportBugModal,
  toggleReIndexingModal,
  toggleChangePasswordModal,
  toggleCancelSubscriptionModal,
  toggleAddPaymentMethodModal,
  toggleRequestPricingModal,
  toggleMobileMenuOpen,
  toggleAccessDeniedModal,
  toggleRequestUpgradeModal,
  setAccessKey,
  togglePushToCMSModal,
  toggleRequestIntegrationModal,
  toggleIntegrationConnectedModal,
  toggleBrandGuidelinesUploadModal,
  toggleKeyImprovementsModal,
  setBulkJobTab,
  setAutopilotJobTab,
  setOptimizeAssetsCounter,
  decreaseOptimizeAssetsCounter,
  setOnboardingData,
  setNoContentPushed,
  toggleActivityLogModal,
  toggleUndoContentUpdateModal,
  setHiddenSideBarIcons,
  toggleConfirmShopifyPlanCancelModal,
  setAvatarUploaderModalOpenFor,
} = navigationSlice.actions;
export default navigationSlice.reducer;

export const selectNavigation = (state) => state.navigation;
export const selectNavigationLoading = (state) => state.navigation.loading;
export const selectSideBarOpen = (state) => state.navigation.sideBarOpen;

export const selectRegenerateAllRejectedModal = (state) =>
  state.navigation.regenerateAllRejectedModal;
export const selectPushAllItemsToCMSModal = (state) =>
  state.navigation.pushAllItemsToCMSModal;
export const selectReportBugModal = (state) => state.navigation.reportBugModal;
export const selectInviteMemberModal = (state) =>
  state.navigation.inviteMemberModal;
export const selectInviteMemberSentModal = (state) =>
  state.navigation.inviteMemberSentModal;
export const selectDeleteMemberModal = (state) =>
  state.navigation.deleteMemberModal;
export const selectChangePasswordModal = (state) =>
  state.navigation.changePasswordModal;
export const selectCancelSubscriptionModal = (state) =>
  state.navigation.cancelSubscriptionModal;
export const selectAddPaymentMethodModal = (state) =>
  state.navigation.addPaymentMethodModal;
export const selectRequestPricingModal = (state) =>
  state.navigation.requestPricingModal;

export const selectMobileMenuOpen = (state) => state.navigation.mobileMenuOpen;

export const selectAccessDeniedModal = (state) =>
  state.navigation.accessDeniedModal;

export const selectActivityLogModalOpen = (state) =>
  state.navigation.activityLogModalOpen;

export const selectUndoContentUpdateModalOpen = (state) =>
  state.navigation.undoContentUpdateModalOpen;

export const selectReIndexingModal = (state) =>
  state.navigation.reIndexingModal;

export const selectRequestUpgradeModal = (state) =>
  state.navigation.requestUpgradeModal;

export const selectRequestIntegrationModal = (state) =>
  state.navigation.requestIntegrationModal;

export const selectBrandGuidelinesUploadModal = (state) =>
  state.navigation.brandGuidelinesUploadModal;

export const selectAccessKey = (state) => state.navigation.accessKey;

export const selectPushToCMSModal = (state) => state.navigation.pushToCMSModal;
export const selectPushToCMSConfirmationModal = (state) =>
  state.navigation.pushToCMSConfirmationModal;
export const selectSetupAutomaticIndexingModal = (state) =>
  state.navigation.setupAutomaticIndexingModal;
export const selectIntegrationConnectedModal = (state) =>
  state.navigation.integrationConnectedModal;
export const selectKeyImprovementsModal = (state) =>
  state.navigation.keyImprovementsModal;
export const selectBulkJobTab = (state) => state.navigation.bulkJobTab;
export const selectAutopilotJobTab = (state) =>
  state.navigation.autopilotJobTab;
export const selectSideBarCounters = (state) =>
  state.navigation.sideBarCounters;
export const selectOnboardingData = (state) => state.navigation.onboarding;
export const selectHiddenSideBarIcons = (state) =>
  state.navigation.hiddenSideBarIcons;
export const selectStripeLoadings = (state) => state.navigation.stripeLoaders;
export const selectConfirmShopifyPlanCancelModal = (state) =>
  state.navigation.confirmShopifyPlanCancelModal;
export const selectAvatarUploaderModalOpenFor = (state) =>
  state.navigation.avatarUploaderModalOpenFor;
