import { makeRequest } from '@api/client';

const getAuthUserDetails = async ({ extraAttributes }) => {
  return await makeRequest(`user-details/`, { method: 'GET' }, extraAttributes);
};

const updateUserRequest = async (body, headers) => {
  return await makeRequest('user/', {
    method: 'PATCH',
    body,
    headers: headers || {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const userReportBug = async (body) => {
  return await makeRequest(`user-report-bug/`, {
    method: 'POST',
    body: body,
  });
};

const userRequestUpgrade = async (body) => {
  return await makeRequest(`user-request-plan-upgrade/`, {
    method: 'POST',
    body: body,
  });
};

const userRequestIntegration = async (body) => {
  return await makeRequest(`api-integration/request-new-integration/`, {
    method: 'POST',
    body: body,
  });
};

const updatePaymentStatus = async (body) => {
  return await makeRequest('billing/check-payment', {
    method: 'POST',
    body: body,
  });
};

const getUserAvatar = async () => {
  return await makeRequest('user-avatar-decode/', {
    method: 'GET',
  });
};

const getPDPUsageRequest = async () => {
  return await makeRequest('pdp/', {
    method: 'GET',
  });
};

const getActivityLogByTypeAPI = async ({
  type,
  page = 1,
  pageNumber = 10,
  publicShopId = null,
}) => {
  return await makeRequest(
    `activity-log/${type}?page=${page}&page_size=${pageNumber}${
      publicShopId ? `&public_shop_id=${publicShopId}` : ''
    }`,
    {
      method: 'GET',
    }
  );
};

const undoContentUpdateAPI = async ({ activityLogId }) => {
  return await makeRequest(`undo-content-update/${activityLogId}`, {
    method: 'POST',
  });
};

const getUserInvoicesHistoryAPI = async ({ page = 1, pageSize = 10 }) => {
  return await makeRequest(`user-invoices?page=${page}&page_size=${pageSize}`, {
    method: 'GET',
  });
};

const cancelShopifySubscriptionAPI = async (body) => {
  return await makeRequest(`billing/cancel-shopify-subscription`, {
    method: 'POST',
    body: body,
  });
};

export {
  getAuthUserDetails,
  updateUserRequest,
  userReportBug,
  userRequestUpgrade,
  userRequestIntegration,
  getUserAvatar,
  updatePaymentStatus,
  getPDPUsageRequest,
  getActivityLogByTypeAPI,
  undoContentUpdateAPI,
  getUserInvoicesHistoryAPI,
  cancelShopifySubscriptionAPI,
};
