import { GREY, COMMON, PRIMARY } from '@theme/modes/common';
import { DARK } from '@theme/shadows';

const styles = {
  ...COMMON,
  text: {
    primary: '#ffffff',
    secondary: '#ffffff',
    disabled: GREY[600],
    neutral: GREY[800],
    alternative: '#FFFA77',
    contrastText: '#000',
    alternativeContrastText: '#000',
    link: '#ffffff',
    customTitle: '#ffffff',
    danger: 'rgb(240, 27, 27)',
  },
  input: {
    border: '#38505B',
  },
  disableButtonText: 'rgba(131, 131, 131, 1)',
  alternative: '#FFFA77',
  sidebar: {
    background: '#232C2C',
    border: 'transparent',
    borderDivider: '#2D4955',
    icon: {
      default: '#ffffff',
      active: '#FFFA77',
      background: '#1F2727',
      backgroundActive: '#FFF4B926',
    },
  },
  optimizationLog: {
    rowRevertion:
      'linear-gradient(135deg, rgba(255, 250, 119, 0.15) 0%, rgba(255, 244, 185, 0.5) 100%)',
    approvedSection: {
      headerBackground: '#077655',
    },
  },
  auth: {
    input: '#1F2727',
  },
  datePicker: {
    border: '#2D4955',
  },
  background: {
    paper: '#1B2222',
    default: '#1B2222',
    neutral: GREY[500_16],
  },
  table: {
    background: {
      default: '#1F2727',
      header: '#232C2C',
    },
    border: '#2D4955',
    primary: '#1B2222',
    stickyHeader: '#232C2C',
    alternative: '#FFFA77',
    iconButtonBackground: '#CBA376',
  },
  shadows: DARK,
  action: { active: GREY[500], ...COMMON.action },
  account: {
    background: '#232C2C',
    header: '#1F2727',
  },
  cards: {
    backgrounds: {
      primary: '#1F2727',
      secondary: '#1F2727',
    },
    shadows: {
      primary: 'transparent',
      secondary: 'transparent',
    },
    border: 'transparent',
    skeletonBorder: '#1F2727',
    planRailBackground: '#EEEEEE',
    subscriptionBackground: '#1F2727',
    cardPrice: '#FFFA77',
    checkGradient: {
      from: '#FFFA77',
      to: '#FFF4B9',
    },
    integrationBackground: '#1F2727',
    integrationText: '#fff',
    settings: {
      background: '#1F2727',
      shadow: 'none',
    },
    members: {
      background: '#1F2727',
      shadow: 'none',
    },
    optimize: {
      notPushed: '#942430',
      pushed: '#FFFA77',
    },
    assetsBackground: '#1F2727',
    plan: {
      essential: {
        background: '#1F2727',
        text: '#FDFAF7',
        border: GREY[400],
      },
      growth: {
        background: '#1F2727',
        text: '#FDFAF7',
        border: GREY[400],
      },
      enterprise: {
        background: '#FDFAF7',
        text: GREY[800],
        border: GREY[400],
      },
    },
  },
  switch: {
    active: '#FFFA77',
    inactive: '#FFF4B926',
    theme: {
      text: '#FFFA77',
      checked: GREY[0],
      trackBackground: '#FFFA77',
      background: '#232C2C',
    },
  },
  dropdowns: {
    secondary: '#1F2727',
    border: '#2D4955',
    darker: '#232C2C',
    text: '#fff',
    indicatorColor: '#fff',
    parentContainer: 'transparent',
    childContainer: '#1F2727',
    singleOptionBackground: '#232C2C',
    multiOptionBackground: '#1F2727',
    checkBox: '#FFFA77',
    member: {
      backgroundSelector: '#fff',
      backgroundOptions: '#fff',
      border: '#1F2727',
    },
    storeVariant: {
      text: '#fff',
      backgroundIconHover: '#232C2C',
    },
    defaultIconBackground: '#919352',
  },
  loader: {
    spinner: {
      border: PRIMARY['main'],
      shadow: PRIMARY['dark'],
      shadowContrast: '#FFF4B9',
      background: '#00000099',
    },
  },
  various: {
    buttonOptions: '#FF4E8D',
    matchText: '#FFFA77',
    newText: '#68B0B0',
    dashboardsCardsText: '#fff',
    softIcons: '#FFF',
    divider: '#FFF',
    errorPlaceholder: '#FF3B3B',
    errorMessages: '#FF2424',
    badgeCounter: '#D42C9B',
    emailButton: '#1F2727',
  },
  icon: {
    primary: '#FFFA77',
    questionMark: '#ABB1B3',
  },
  dashboard: {
    activityLog: {
      borderDivider: '#FFFA77',
      itemBackground: '#1F2727',
    },
    graphic: {
      subtitle: '#fff',
      containerBg: '#1F2727',
      gridColor: '#FFF4B926',
      optimized: '#943432',
      nonOptimized: '#434C4C',
      sessions: '#C9412633',
      conversions: '#888888',
      filter: {
        optionSelected: '#FFFA77',
        option: '#232C2C',
      },
    },
    navbar: {
      stickyBackground: '#232C2C',
    },
  },
  keywords: {
    input: '#1F2727',
    button: '#FFFA77',
    buttonModal: '#4D3974',
  },
  checkBox: {
    border: '#C5CBD3',
    background: '#FFFA77',
    icon: '#000',
  },
  buttons: {
    background: '#FFFA77',
    text: '#000',
    backgroundSecondary: '#2D4955',
    border: '#2D4955',
    borderSecondary: '#000',
  },
  logoutButton: {
    background: COMMON.common.white,
    text: '#000',
  },
  GSCButton: {
    backgroundCard: '#1F2727',
    backgroundButton: '#3f3037',
  },
  stepper: {
    disabled: '#ECECEC',
    dividerBorder: GREY[300],
  },
  permissionsDenied: {
    gradient: {
      from: '#942430',
      to: '#FFFA77',
    },
    denied: '#C64865',
  },
  successsView: {
    borderContainer: '#1F2727',
    iconBorder: '#cbd1d8',
    backgroundContainer: '#1F2727',
  },
  cmsIntregrations: {
    backgroundLogo: 'transparent',
    borderLogo: '#fff',
    borderSelectedLogo: '#AEA6C4',
    backgroundSelectedLogo: '#8C83C333',
  },
  dialog: {
    background: '#c5cbd380',
    backgroundContainer: '#232C2C',
    resultBg: '#1F2727',
  },
  metrics: {
    card: {
      background: {
        default: '#1F2727',
        main: '#1F2727',
        secondary: '#232C2C',
      },
    },
  },
  snackBar: {
    background: {
      success: PRIMARY['darker'],
      error: PRIMARY['darker'],
    },
  },
  subscription: {
    alternative: '#FFFA77',
  },
  brandGuideline: {
    background: '#1F2727',
  },
  slider: {
    background: '#FFFA77',
    border: '#942430',
  },
  textEditor: {
    outputBackground: '#1F2727',
    delimiter: 'FFFA77',
    attribute: '68B0B0',
    attributeValue: 'EC7B7B',
  },
  cmsIcons: {
    ...COMMON.cmsIcons,
    woocommerce: {
      ...COMMON.cmsIcons.woocommerce,
      activityLogVersion: {
        stroke: '#fff',
        svgFill: '#1F2727',
      },
      cardVersion: {
        stroke: 'none',
        svgFill: '#fff',
      },
      detailTableButtonVersion: {
        stroke: 'none',
        svgFill: '#000',
      },
    },
    shopify: {
      ...COMMON.cmsIcons.shopify,
      activityLogVersion: {
        stroke: '#fff',
        svgFill: '#1F2727',
      },
      cardVersion: {
        stroke: '#fff',
        svgFill: '#1F2727',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    magento: {
      ...COMMON.cmsIcons.magento,
      cardVersion: {
        stroke: '#fff',
        svgFill: '#1F2727',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    akeneo: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        stroke: '#000',
        svgFill: '#000',
      },
    },
    bigcommerce: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        stroke: '#000',
      },
    },
    webflow: {
      ...COMMON.cmsIcons.akeneo,
      cardVersion: {
        stroke: '#fff',
      },
      detailTableButtonVersion: {
        svgFill: '#000',
      },
    },
    hibbett: {
      ...COMMON.cmsIcons.hibbett,
      cardVersion: {
        stroke: '#fff',
      },
    },
  },
  moderationCard: {
    iconBackground: '#1F2727',
    containerBackground: '#1F2727',
    iconContainerBackground: '#000',
    status: {
      success: '#3DC292',
      error: '#FF4F4F',
      warning: '#FF994F',
    },
  },
  onboardingDynamic: {
    rulesContainerBg: '#1F2727',
    rulesContainerSettingsBg: '#1F2727',
    gscCardBg: '#1F2727',
    rulesBg: '#1F2727',
  },
  tinymce: {
    background: '#1F2727',
    backgroundHeader: '#232C2C',
    html: '#FFFA77',
    variables: '#68B0B0',
    values: '#EC7B7B',
  },
  feedback: {
    background: '#1F2727',
    inputBackground: '#232C2C',
  },
  skeleton: {
    background: '#FFF4B926',
  },
  textField: {
    border: '#38505B',
    focusedBorder: '#FFFFFF',
    asterik: '#DD7380',
  },
  onboarding: {
    text: '#fff',
    highlight: '#232C2C',
    containerBg: '#1B2222',
    successBg: '#1F2727',
    mostPopularBg: '#1F2727',
    mostPopular: '#fff',
    nextButton: '#000',
    nextButtonBg: '#FFFA77',
    stepperBg: '#232C2C',
    buttonBlue: '#fff',
    // Unused (old stepper)
    cardBg: '#952431',
    cardBorder: '#952431',
    progressBg: '#FFFA77',
    buttonText: '#000',
    buttonBorder: '#828282',
    buttonDisabled: '#FFF4B926',
    disabledCardBg: '#f4e9ea',
    disabledCardBorder: '#f4e9ea',
    disabledCardText: '#000',
  },
  billing: {
    text: '#bbbfc1',
  },
  tag: {
    active: '#077655',
    inactive: '#444B4A',
    invited: '#fff',
  },
  chat: {
    bubble: {
      background: '#919352',
    },
    background: { container: '#91935240', ai: '#8f8f50', user: '#a4a455' },
    textColor: { ai: '#fff', user: '#fff' },
    actionColor: GREY[600],
  },
};

export default styles;
