import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { authPaths } from '@routes/paths';

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { pathname } = useLocation();
  const isAuthenticated = localStorage.getItem('accessToken') !== null;
  if (isAuthenticated) {
    const redirectTo = authPaths.home;
    return <Navigate to={redirectTo} state={{ prevState: pathname }} />;
  }
  return <>{children}</>;
}
