// @import Dependencies
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// @import Utilities
import { nomenclatureSnack } from '@utils/nomenclature';
import { defaultMetricParams } from '@shared/Dashboard/Utils';
import { isDemoAccount } from '@utils/validations';

// @import Services
import {
  getDashboardDataAPI,
  addPublicShopAPI,
  getDashboardDataByIdAPI,
  getGoogleSearchConsoleAuthAPI,
  getDashboardOverviewDataByIdAPI,
  getDashboardProductPerformanceAPI,
  getDashboardOptimizationPerformanceAPI,
  getDashboardOptimizationProductsAPI,
  getDashboardPerformanceCardsByIdAPI,
  getDashboardPerformanceLineGraphicByIdAPI,
  getDashboardMetadataByIdAPI,
  getDashboardProductTableAPI,
  getDashboardTopPerformersAPI,
  setFullAutoApprovalAPI,
  getAdminTableDataAPI,
  getAdminOverviewDataAPI,
  getAdminHistoricalDataAPI,
  generateDashboardProductTableCSVAPI,
  getShopPlanDetailsAPI,
  getShopUsageStatisticsAPI,
  getShopBillingStatisticsAPI,
} from '@api/dashboard';

// @import Reducers
import { setOnboardingData } from '@redux/slices/navigation';
import { compareDatesAfter, formatDateByMonthAndDay } from '@utils/date';
import { setCreatingLatestJob } from '@redux/slices/optimizationLog';
import i18next from 'i18next';

// @import Data
import mockResponseData from '@data/usage-sample.json';

export const initialState = {
  loading: false,
  loadingPublicShopPagination: false,
  loadingPublicShopMetadata: false,
  loadingPublicShop: false,
  loadingGoogleAuth: false,
  loadingOverviewData: false,
  loadingOptimizationPerformance: false,
  loadingIndividualPerformance: false,
  loadingDashboardStatisticsCards: false,
  loadingCardMetrics: false,
  loadingLineGraphicMetrics: false,
  loadingDashboardProductTable: false,
  loadingTopPerformers: false,
  loadingDashboardProductTablePagination: false,
  loadingFullAutoApproval: false,
  loadingGenerateDashboardProductTableCSV: false,
  loadingShopPlanDetails: false,
  loadingShopUsageStatistics: false,
  loadingShopBillingStatistics: false,
  targetedOptimizationTable: { rowsRender: 10 },
  currentShopData: null,
  optimizationPerformanceData: null,
  shopURLData: null,
  productsPagination: {
    currentPage: 0,
    hasMore: false,
  },
  metrics: {
    data: null,
    params: defaultMetricParams,
    dates: null,
    view: '1M',
    type: 'sessions',
    table: {
      pdpsOptimizedCount: 0,
      productCount: 0,
      searchBy: 'id',
      optimized: true,
      pagination: {
        hasMore: false,
        page: 1,
      },
    },
    shopLevelPerformanceType: {
      sessions: {
        type: 'Previous',
      },
      conversions: {
        type: 'Previous',
      },
    },
    topPerformers: {
      type: 'sessions',
      data: [],
    },
  },
  shopsPagination: {
    currentPage: 0,
    hasMore: false,
    list: [],
  },
  adminData: {
    table: [],
    overview: null,
    historical: null,
    page: 1,
    hasMore: false,
    loading: false,
    loadingPagination: false,
    loadingOverview: false,
    loadingHistorical: false,
  },
  selectedProduct: null,
  productOnRegeneration: null,
  shopPlanDetails: null,
  shopUsageStatistics: null,
  shopBillingStatistics: null,
};
// ------------------THUNKS----------------
export const getDashboardData = createAsyncThunk(
  'cms/getDashboardData',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getDashboardDataAPI(body);
    if (isSuccessful) {
      if (!body?.page || body?.page === 1)
        thunkAPI.dispatch(setOnboardingData(data));
      return {
        shops: data,
        page: body?.page || 1,
        pageSize: body?.pageSize || 50,
        isSuccessful,
      };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getDashboardDataById = createAsyncThunk(
  'cms/getDashboardDataById',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getDashboardDataByIdAPI(id);
    if (isSuccessful) {
      return data;
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getDashboardMetadataById = createAsyncThunk(
  'cms/getDashboardMetadataById',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getDashboardMetadataByIdAPI(
      id
    );
    if (isSuccessful)
      return {
        ...data,
        value: data.id,
        label: data.display_name ?? data.public_shop_url,
        customIcon: data.integrated_google_analytics ? 'GoogleAnalytics' : null,
        customIconColor: data.integrated_google_analytics
          ? {
              stroke: 'none',
              color: '#F9AB00',
              svgFill: '#E37400',
            }
          : null,
      };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getDashboardPerformanceCardsById = createAsyncThunk(
  'cms/getDashboardPerformanceCardsById',
  async ({ id, params }, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardPerformanceCardsByIdAPI({
        id,
        params,
      });
    if (isSuccessful) return { id, data };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getDashboardPerformanceLineGraphicById = createAsyncThunk(
  'cms/getDashboardPerformanceLineGraphicById',
  async ({ id, params }, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardPerformanceLineGraphicByIdAPI({
        id,
        params,
      });
    if (isSuccessful) return { id, data };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getGoogleSearchConsoleAuth = createAsyncThunk(
  'cms/getGoogleSearchConsoleAuth',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getGoogleSearchConsoleAuthAPI(params);
    if (isSuccessful) return { statusKey, data };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const addPublicShop = createAsyncThunk(
  'cms/addPublicShop',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await addPublicShopAPI(body);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getDashboardOverviewDataById = createAsyncThunk(
  'cms/getDashboardOverviewDataById',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardOverviewDataByIdAPI(id);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getDashboardProductPerformance = createAsyncThunk(
  'cms/getDashboardProductPerformance',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardProductPerformanceAPI(id);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getDashboardOptimizationPerformance = createAsyncThunk(
  'cms/getDashboardOptimizationPerformance',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardOptimizationPerformanceAPI(id);
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getDashboardOptimizationProducts = createAsyncThunk(
  'cms/getDashboardOptimizationProducts',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardOptimizationProductsAPI(body);
    if (isSuccessful) return { products: data, ...body };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getDashboardProductTable = createAsyncThunk(
  'cms/getDashboardProductTable',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getDashboardProductTableAPI(
      params
    );
    if (isSuccessful) return { data, id: params.id, page: params.page };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const generateDashboardProductTableCSV = createAsyncThunk(
  'cms/getDashboardPerformanceProductsTableAllItems',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await generateDashboardProductTableCSVAPI(params);
    if (isSuccessful) return { data, id: params.id, statusKey };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getDashboardTopPerformers = createAsyncThunk(
  'cms/getDashboardTopPerformers',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } =
      await getDashboardTopPerformersAPI(params);
    if (isSuccessful) return { data };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getAdminTableData = createAsyncThunk(
  'cms/getAdminTableData',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getAdminTableDataAPI(
      params
    );
    if (isSuccessful) return { data, page: params.page };
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getAdminOverviewData = createAsyncThunk(
  'cms/getAdminOverviewData',
  async (_, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getAdminOverviewDataAPI();
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getAdminHistoricalData = createAsyncThunk(
  'cms/getAdminHistoricalData',
  async (_, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getAdminHistoricalDataAPI();
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const setFullAutoApproval = createAsyncThunk(
  'cms/setFullAutoApproval',
  async ({ body, id }, { rejectWithValue, dispatch }) => {
    try {
      const response = await setFullAutoApprovalAPI({ body, id });
      if (!response.isSuccessful) {
        return rejectWithValue(response);
      }
      if (!body?.toggle_auto_approval) {
        dispatch(setCreatingLatestJob(true));
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getShopPlanDetails = createAsyncThunk(
  'cms/getShopPlanDetails',
  async ({ id, params }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getShopPlanDetailsAPI(
      id,
      params
    );
    if (isSuccessful) return data;
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getShopUsageStatistics = createAsyncThunk(
  'cms/getShopUsageStatistics',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getShopUsageStatisticsAPI(
      id
    );
    if (isSuccessful) {
      if (isDemoAccount(thunkAPI.getState()?.user?.data))
        return mockResponseData.usageStats;
      return data;
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getShopBillingStatistics = createAsyncThunk(
  'cms/getShopBillingStatistics',
  async (id, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getShopBillingStatisticsAPI(
      id
    );
    if (isSuccessful) {
      if (isDemoAccount(thunkAPI.getState()?.user?.data))
        return mockResponseData.billingStats;
      return data;
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

const formatDate = (date) =>
  date ? formatDateByMonthAndDay(date, 'MMM D, YYYY') : null;

export const sharedExtraReducers = (builder) => {
  builder
    .addCase(getDashboardData.pending, (state) => {
      state.loadingPublicShopPagination = true;
    })
    .addCase(getDashboardData.rejected, (state, action) => {
      state.loadingPublicShopPagination = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getDashboardData.fulfilled, (state, action) => {
      const { shops, page, pageSize } = action.payload;
      const oldShops = page >= 2 ? state.shopsPagination.list : [];
      const newShopsList = [...oldShops, ...shops]
        .filter(
          (shop, index, self) =>
            index === self.findIndex((s) => s.id === shop.id)
        )
        .map((_s) => {
          return {
            ..._s,
            value: _s?.id,
            label: _s?.display_name ?? _s?.public_shop_url,
            customIcon: _s?.integrated_google_analytics
              ? 'GoogleAnalytics'
              : null,
            customIconColor: _s?.integrated_google_analytics
              ? {
                  stroke: 'none',
                  color: '#F9AB00',
                  svgFill: '#E37400',
                }
              : null,
          };
        });
      state.shopsPagination = {
        currentPage: page,
        hasMore: shops.length === pageSize,
        list: newShopsList,
      };
      state.loadingPublicShopPagination = false;
    })
    .addCase(getDashboardDataById.pending, (state) => {
      state.loadingPublicShop = true;
    })
    .addCase(getDashboardDataById.rejected, (state, action) => {
      state.loadingPublicShop = false;
      state.currentShopData = null;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getDashboardDataById.fulfilled, (state, action) => {
      state.loadingPublicShop = false;
      state.currentShopData = action?.payload;
    })
    .addCase(getGoogleSearchConsoleAuth.pending, (state) => {
      state.loadingGoogleAuth = true;
    })
    .addCase(getGoogleSearchConsoleAuth.rejected, (state, action) => {
      state.loadingGoogleAuth = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getGoogleSearchConsoleAuth.fulfilled, (state, action) => {
      state.loadingGoogleAuth = false;
      const { statusKey, data } = action.payload;
      nomenclatureSnack({
        type: 'success',
        message: statusKey,
      });
      window.open(data.url, '_blank');
    })
    .addCase(addPublicShop.pending, (state) => {
      state.loadingPublicShop = true;
    })
    .addCase(addPublicShop.rejected, (state, action) => {
      state.loadingPublicShop = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(addPublicShop.fulfilled, (state) => {
      state.loadingPublicShop = false;
    })
    .addCase(getDashboardOverviewDataById.pending, (state) => {
      state.loadingOverviewData = true;
    })
    .addCase(getDashboardOverviewDataById.rejected, (state) => {
      state.loadingOverviewData = false;
    })
    .addCase(getDashboardOverviewDataById.fulfilled, (state) => {
      state.loadingOverviewData = false;
    })
    .addCase(getDashboardProductPerformance.pending, (state) => {
      state.loadingIndividualPerformance = true;
    })
    .addCase(getDashboardProductPerformance.rejected, (state) => {
      state.loadingIndividualPerformance = false;
    })
    .addCase(getDashboardProductPerformance.fulfilled, (state) => {
      state.loadingIndividualPerformance = false;
    })
    .addCase(getDashboardOptimizationPerformance.pending, (state) => {
      state.loadingOptimizationPerformance = true;
    })
    .addCase(getDashboardOptimizationPerformance.rejected, (state) => {
      state.loadingOptimizationPerformance = false;
    })
    .addCase(getDashboardOptimizationPerformance.fulfilled, (state) => {
      state.loadingOptimizationPerformance = false;
    })
    .addCase(getDashboardPerformanceCardsById.pending, (state) => {
      state.loadingCardMetrics = true;
    })
    .addCase(getDashboardPerformanceCardsById.rejected, (state) => {
      state.loadingCardMetrics = false;
    })
    .addCase(getDashboardPerformanceCardsById.fulfilled, (state, action) => {
      state.loadingCardMetrics = false;
      const { id, data } = action.payload;
      const { dates, metrics } = data;
      const transformMetricCardsData = (metricsCardsData) => {
        if (!metricsCardsData) return [];
        return Object.keys(metricsCardsData).map((key) => ({
          name: key,
          ...metricsCardsData[key],
        }));
      };
      const transformedMetricsCards = transformMetricCardsData(metrics);

      state.metrics = {
        ...state.metrics,
        data: {
          ...state.metrics.data,
          [id]: {
            ...state.metrics.data?.[id],
            cards: transformedMetricsCards,
            dates: {
              currentPeriodFrom: formatDate(dates?.from_date),
              currentPeriodTo: formatDate(dates?.to_date),
              previousPeriodFrom: formatDate(dates?.previous_period_from_date),
              previousPeriodTo: formatDate(dates?.previous_period_to_date),
              yoyFrom: formatDate(dates?.yoy_from_date),
              yoyTo: formatDate(dates?.yoy_to_date),
            },
          },
        },
      };
    })
    .addCase(getDashboardPerformanceLineGraphicById.pending, (state) => {
      state.loadingLineGraphicMetrics = true;
    })
    .addCase(getDashboardPerformanceLineGraphicById.rejected, (state) => {
      state.loadingLineGraphicMetrics = false;
    })
    .addCase(
      getDashboardPerformanceLineGraphicById.fulfilled,
      (state, action) => {
        state.loadingLineGraphicMetrics = false;
        const { id, data } = action.payload;
        const {
          data: rawData,
          totals,
          ekom_breakpoint_date: ekomBreakpointDate,
        } = data;

        const transformDataForChart = (rawData) => {
          const labels = rawData.map((item) => item.date);
          const optimizedData = rawData.map((item) => item.optimized_value);
          const unoptimizedData = rawData.map((item) => item.unoptimized_value);
          return {
            labels,
            datasets: [
              {
                type: 'optimized',
                data: optimizedData,
              },
              {
                type: 'unoptimized',
                data: unoptimizedData,
              },
            ],
          };
        };

        const { labels, datasets } = transformDataForChart(rawData);
        const breakpointDate = formatDate(ekomBreakpointDate);
        // Find the index of the breakpoint date in the labels array
        let breakpointDateIndex = labels.indexOf(ekomBreakpointDate);

        if (breakpointDateIndex === -1) {
          // Find the first label after the breakpoint if the breakpoint date is not in the labels array
          breakpointDateIndex = labels.findIndex((label) =>
            compareDatesAfter(label, breakpointDate)
          );
        }

        const formattedLabels = labels.map((label) =>
          formatDateByMonthAndDay(label, 'MMM D')
        );

        state.metrics = {
          ...state.metrics,
          data: {
            ...state.metrics.data,
            [id]: {
              ...state.metrics.data?.[id],
              graphic: {
                datasets,
                labels: formattedLabels,
                totals,
                breakpointDateIndex,
                breakpointDate,
              },
            },
          },
        };
      }
    )
    .addCase(getDashboardOptimizationProducts.pending, () => {})
    .addCase(getDashboardOptimizationProducts.rejected, () => {})
    .addCase(getDashboardOptimizationProducts.fulfilled, (state, action) => {
      const { products, page } = action.payload;
      state.productsPagination = {
        currentPage: page,
        hasMore: products.length === 10,
      };
    })
    .addCase(getDashboardMetadataById.pending, (state) => {
      state.loadingPublicShopMetadata = true;
    })
    .addCase(getDashboardMetadataById.rejected, (state) => {
      state.loadingPublicShopMetadata = false;
    })
    .addCase(getDashboardMetadataById.fulfilled, (state, action) => {
      state.loadingPublicShopMetadata = false;
      state.shopsPagination = {
        currentPage: state.shopsPagination.currentPage,
        hasMore: state.shopsPagination.hasMore,
        list: [...state.shopsPagination.list, action?.payload],
      };
    })
    .addCase(getDashboardProductTable.pending, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.loadingDashboardProductTable = true;
      } else {
        state.loadingDashboardProductTablePagination = true;
      }
    })
    .addCase(getDashboardProductTable.rejected, (state) => {
      state.loadingDashboardProductTable = false;
      state.loadingDashboardProductTablePagination = false;
    })
    .addCase(getDashboardProductTable.fulfilled, (state, action) => {
      state.loadingDashboardProductTable = false;
      state.loadingDashboardProductTablePagination = false;
      const { id, page, data } = action.payload;

      state.metrics = {
        ...state.metrics,
        data: {
          ...state.metrics.data,
          [id]: {
            ...state.metrics.data?.[id],
            table: {
              ...state.metrics.data?.[id]?.table,
              data:
                page === 1
                  ? data?.results
                  : [
                      ...new Map(
                        [
                          ...(state.metrics.data?.[id]?.table?.data ?? []),
                          ...data.results,
                        ].map((item) => [item.id, item])
                      ).values(),
                    ],
            },
          },
        },
        table: {
          ...state.metrics.table,
          pdpsOptimizedCount: data?.optimized_products_count,
          productCount: data?.active_products_count,
          lastPullProduct: data?.last_pull_product_date,
          pagination: {
            hasMore: data?.next !== null,
            page,
          },
        },
      };
    })
    .addCase(getDashboardTopPerformers.pending, (state) => {
      state.loadingTopPerformers = true;
    })
    .addCase(getDashboardTopPerformers.rejected, (state) => {
      state.loadingTopPerformers = false;
    })
    .addCase(getDashboardTopPerformers.fulfilled, (state, action) => {
      const response = action.payload;

      const sortedData = response.data.sort(
        (a, b) => b.current_total - a.current_total
      );
      state.metrics.topPerformers.data = sortedData;

      state.loadingTopPerformers = false;
    })
    .addCase(setFullAutoApproval.pending, (state) => {
      state.loadingFullAutoApproval = true;
    })
    .addCase(setFullAutoApproval.fulfilled, (state, action) => {
      state.loadingFullAutoApproval = false;
      state.currentShopData = { ...state.currentShopData, ...action.payload };
      state.shopURLData = { ...state.shopURLData, ...action.payload };
    })
    .addCase(setFullAutoApproval.rejected, (state) => {
      state.loadingFullAutoApproval = false;
    })
    .addCase(getAdminTableData.pending, (state, action) => {
      if (action.meta.arg.page === 1) {
        state.adminData.loading = true;
      } else {
        state.adminData.loadingPagination = true;
      }
    })
    .addCase(getAdminTableData.fulfilled, (state, action) => {
      state.adminData.loading = false;
      state.adminData.loadingPagination = false;
      const formattedData = action.payload.data.map((item) => ({
        ...item,
        plan_period:
          item.plan_period.start_period && item.plan_period.end_period
            ? `${formatDate(item.plan_period.start_period)} - ${formatDate(
                item.plan_period.end_period
              )}`
            : null,
        cadence_start_date: formatDate(item.cadence_start_date),
        next_pull_sync: formatDate(item.next_pull_sync),
        next_cadence_cycle: formatDate(item.next_cadence_cycle),
        full_catalog_optimization_date: formatDate(
          item.full_catalog_optimization_date
        ),
        aws_cogs: item?.cogs_breakdown?.aws.total_cost,
        openai_cogs: item?.cogs_breakdown?.openai.total_cost,
        semrush_cogs: item?.cogs_breakdown?.semrush.total_cost,
      }));

      const existingData = state.adminData.table || [];
      const mergedData = [...existingData, ...formattedData];
      const uniqueData = Array.from(
        new Map(mergedData.map((item) => [item.id, item])).values()
      );

      state.adminData = {
        ...state.adminData,
        table: uniqueData,
        page: action.payload.page,
        hasMore: action.payload.data.length === 20,
      };
    })
    .addCase(getAdminTableData.rejected, (state) => {
      state.adminData.loading = false;
      state.adminData.loadingPagination = false;
    })
    .addCase(getAdminOverviewData.pending, (state) => {
      state.adminData.loadingOverview = true;
    })
    .addCase(getAdminOverviewData.fulfilled, (state, action) => {
      state.adminData.loadingOverview = false;
      const {
        average_costs_per_pdp,
        average_total_cost_per_pdp,
        interval_metrics,
      } = action.payload;

      const filteredIntervalMetrics = interval_metrics
        .filter((metric) =>
          ['30 Days', '90 Days', '6 Months'].includes(metric.interval)
        )
        .sort((a, b) => {
          const order = ['30 Days', '90 Days', '6 Months'];
          return order.indexOf(a.interval) - order.indexOf(b.interval);
        });

      const formattedData = {
        ...action.payload,
        pdpCosts: [
          {
            title: i18next.t('adminDashboard.pdpCostMetrics.averageOpenAICost'),
            icon: {
              name: 'OpenAILogo',
              width: '24px',
              height: '24px',
              className: 'mr-2',
            },
            amount: average_costs_per_pdp.openAI.toFixed(3),
          },
          {
            title: i18next.t(
              'adminDashboard.pdpCostMetrics.averageSemrushCost'
            ),
            icon: { name: 'SemrushLogo', width: '48px', height: '48px' },
            amount: average_costs_per_pdp.semrush.toFixed(3),
          },
          {
            title: i18next.t('adminDashboard.pdpCostMetrics.averageAWSCost'),
            icon: {
              name: 'AWSLogo',
              width: '28px',
              height: '24px',
              className: 'mr-2',
            },
            amount: average_costs_per_pdp.AWS.toFixed(3),
          },
          {
            title: i18next.t('adminDashboard.pdpCostMetrics.averageTotalCost'),
            icon: {
              name: 'LogoRaw',
              width: '24px',
              height: '24px',
              className: 'mr-2',
            },
            amount: average_total_cost_per_pdp.toFixed(3),
          },
        ],
        interval_metrics: filteredIntervalMetrics,
      };
      state.adminData = {
        ...state.adminData,
        overview: formattedData,
      };
    })
    .addCase(getAdminOverviewData.rejected, (state) => {
      state.adminData.loadingOverview = false;
    })
    .addCase(getAdminHistoricalData.pending, (state) => {
      state.adminData.loadingHistorical = true;
    })
    .addCase(getAdminHistoricalData.fulfilled, (state, action) => {
      state.adminData.loadingHistorical = false;
      state.adminData.historical = action.payload;
    })
    .addCase(getAdminHistoricalData.rejected, (state) => {
      state.adminData.loadingHistorical = false;
    })
    .addCase(generateDashboardProductTableCSV.pending, (state) => {
      state.loadingGenerateDashboardProductTableCSV = true;
    })
    .addCase(generateDashboardProductTableCSV.fulfilled, (state, action) => {
      state.loadingGenerateDashboardProductTableCSV = false;
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(generateDashboardProductTableCSV.rejected, (state, action) => {
      state.loadingGenerateDashboardProductTableCSV = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getShopPlanDetails.pending, (state) => {
      state.loadingShopPlanDetails = true;
    })
    .addCase(getShopPlanDetails.fulfilled, (state, action) => {
      state.loadingShopPlanDetails = false;
      state.shopPlanDetails = action.payload;
    })
    .addCase(getShopPlanDetails.rejected, (state, action) => {
      state.loadingShopPlanDetails = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getShopUsageStatistics.pending, (state) => {
      state.loadingShopUsageStatistics = true;
    })
    .addCase(getShopUsageStatistics.fulfilled, (state, action) => {
      state.loadingShopUsageStatistics = false;
      state.shopUsageStatistics = action.payload;
    })
    .addCase(getShopUsageStatistics.rejected, (state, action) => {
      state.loadingShopUsageStatistics = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(getShopBillingStatistics.pending, (state) => {
      state.loadingShopBillingStatistics = true;
    })
    .addCase(getShopBillingStatistics.fulfilled, (state, action) => {
      state.loadingShopBillingStatistics = false;
      state.shopBillingStatistics = action.payload?.snapshots;
    })
    .addCase(getShopBillingStatistics.rejected, (state, action) => {
      state.loadingShopBillingStatistics = false;
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    });
};

export const cmsSlice = createSlice({
  name: 'dashboard',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setTargetedOptimizationRowsRenders: (state, action) => {
      state.targetedOptimizationTable.rowsRender = action.payload;
    },
    setOptimizationPerformanceData: (state, action) => {
      state.optimizationPerformanceData = action.payload;
    },
    setShopDataAdded: (state, action) => {
      let newShopData = action?.payload;
      const alreadyExist = state.shopsPagination?.list?.find(
        (_s) => _s?.id === newShopData?.id
      );
      if (!alreadyExist) {
        newShopData = {
          ...newShopData,
          value: newShopData?.id,
          label: newShopData?.display_name ?? newShopData?.public_shop_url,
          customIcon: newShopData?.integrated_google_analytics
            ? 'GoogleAnalytics'
            : null,
          customIconColor: newShopData?.integrated_google_analytics
            ? {
                stroke: 'none',
                color: '#F9AB00',
                svgFill: '#E37400',
              }
            : null,
        };
        const newList = [newShopData, ...state.shopsPagination.list];
        state.shopsPagination = {
          ...state.shopsPagination,
          list: newList,
        };
      }
    },
    setShopURLData: (state, action) => {
      state.shopURLData = action.payload;
    },
    setMetricsParams: (state, action) => {
      const oldState = state.metrics.params;
      const newParams = { ...oldState, ...action.payload };
      const filteredParams = Object.keys(newParams).reduce((acc, key) => {
        if (newParams[key] !== null && newParams[key] !== undefined) {
          acc[key] = newParams[key];
        }
        return acc;
      }, {});

      state.metrics.params = filteredParams;
    },
    setMetricsView: (state, action) => {
      state.metrics.view = action.payload;
    },
    setMetricsType: (state, action) => {
      state.metrics.type = action.payload;
    },
    setShopLevelPerformanceType: (state, action) => {
      state.metrics.shopLevelPerformanceType = {
        ...state.metrics.shopLevelPerformanceType,
        ...action.payload,
      };
    },
    setTopPerformersMetricsType: (state, action) => {
      state.metrics.topPerformers.type = action.payload;
    },
    setMetricTableSearchBy: (state, action) => {
      state.metrics.table.searchBy = action.payload;
    },
    setMetricTableOptimized: (state, action) => {
      state.metrics.table.optimized = action.payload;
    },
    resetProductsTablePagination: (state) => {
      state.metrics.table.pagination.page = 1;
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload;
    },
    setProductOnRegeneration: (state, action) => {
      state.productOnRegeneration = action.payload;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const {
  setTargetedOptimizationRowsRenders,
  setOptimizationPerformanceData,
  setShopDataAdded,
  setShopURLData,
  setMetricsParams,
  setMetricsView,
  setMetricsType,
  setMetricTableSearchBy,
  setMetricTableOptimized,
  resetProductsTablePagination,
  setSelectedProduct,
  setTopPerformersMetricsType,
  setShopLevelPerformanceType,
  setProductOnRegeneration,
} = cmsSlice.actions;
export default cmsSlice.reducer;

// ------------------SELECTORS-------------
export const loadingPublicShop = (state) => state.dashboard.loadingPublicShop;
export const loadingPublicShopPagination = (state) =>
  state.dashboard.loadingPublicShopPagination;
export const loadingPublicShopMetadata = (state) =>
  state.dashboard.loadingPublicShopMetadata;
export const selectloadingDashboardProductTable = (state) =>
  state.dashboard.loadingDashboardProductTable;
export const selectloadingTopPerformers = (state) =>
  state.dashboard.loadingTopPerformers;
export const selectLoadingDashboardProductTablePagination = (state) =>
  state.dashboard.loadingDashboardProductTablePagination;
export const selectLoadingShopPlanDetails = (state) =>
  state.dashboard.loadingShopPlanDetails;
export const selectLoadingShopUsageStatistics = (state) =>
  state.dashboard.loadingShopUsageStatistics;
export const selectLoadingShopBillingStatistics = (state) =>
  state.dashboard.loadingShopBillingStatistics;
export const selectLoadingOverviewData = (state) =>
  state.dashboard.loadingOverviewData;
export const selectLoadingIndividualPerformance = (state) =>
  state.dashboard.loadingIndividualPerformance;
export const selectLoadingOptimizationPerformance = (state) =>
  state.dashboard.loadingOptimizationPerformance;
export const selectTargetedOptimizationRows = (state) =>
  state.dashboard.targetedOptimizationTable.rowsRender;
export const currentShopData = (state) => state.dashboard.currentShopData;
export const selectShopPlanDetails = (state) => state.dashboard.shopPlanDetails;
export const selectShopUsageStatistics = (state) =>
  state.dashboard.shopUsageStatistics;
export const selectShopBillingStatistics = (state) =>
  state.dashboard.shopBillingStatistics;
export const selectOptimizationPerformanceData = (state) =>
  state.dashboard.optimizationPerformanceData;
export const selectProductsPagination = (state) =>
  state.dashboard.productsPagination;
export const selectShopsPagination = (state) => state.dashboard.shopsPagination;
export const selectProductOnRegeneration = (state) =>
  state.dashboard.productOnRegeneration;
export const selectLoadingGenerateDashboardProductTableCSV = (state) =>
  state.dashboard.loadingGenerateDashboardProductTableCSV;

export const selectMetricByDashboardId = (state, dashboardId) => {
  return state.dashboard.metrics.data?.[dashboardId];
};
export const selectShopUrlData = (state) => state.dashboard.shopURLData;
export const selectMetricData = (state) => state.dashboard.metrics;
export const selectMetricTable = (state) => {
  return state.dashboard.metrics.table;
};
export const selectMetricView = (state) => state.dashboard.metrics.view;
export const selectShopLevelPerformanceType = (state) =>
  state.dashboard.metrics.shopLevelPerformanceType;
export const selectLoadingCardMetrics = (state) =>
  state.dashboard.loadingCardMetrics;
export const selectLoadingLineGraphicMetrics = (state) =>
  state.dashboard.loadingLineGraphicMetrics;
export const getSelectedDashboardProduct = (state) =>
  state.dashboard.selectedProduct;
export const getTopPerformerData = (state) =>
  state.dashboard.metrics.topPerformers.data;

export const selectDashboardAdminData = (state) => state.dashboard.adminData;

export const isProductInTable = (state, shopId, productId) => {
  const products = state.dashboard.metrics.data?.[shopId]?.table?.data || [];
  return products.some((product) => product.id === productId);
};
