// @import dependencies
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

// @import paths
import { authPaths } from '@routes/paths';

// @import Reducers
import {
  loadingPublicShop,
  selectShopsPagination,
} from '@redux/slices/dashboard';
import { selectedSharedShop } from '@redux/slices/sharedJobs';

export default function AddIntegrationGuard({ children }) {
  const integrations = useSelector(selectShopsPagination);
  const loading = useSelector(loadingPublicShop);
  const selectedShop = useSelector(selectedSharedShop);

  const { pathname } = useLocation();
  const [loaded, setLoaded] = useState(false);

  const avoidRoutes = [
    authPaths.signUpPlan,
    authPaths.planOverview,
    authPaths.billingOverview,
    authPaths.billingRedirection,
    authPaths.success,
    authPaths.onboarding,
    authPaths.reusableComponents,
  ];
  useEffect(() => {
    if (!loading) setLoaded(true);
  }, [loading]);

  if (loaded) {
    const steps = integrations?.list?.map((_s) =>
      _s?.onboarding_step?.toString()
    );
    const isSelectedShopCompleted = selectedShop
      ? selectedShop?.onboarding_step === '7'
      : true;
    if (!isSelectedShopCompleted && !avoidRoutes.includes(pathname)) {
      const isNew = !steps.length && !isSelectedShopCompleted;
      const redirectTo = `${authPaths.onboarding}${
        isNew ? '?newShop=true' : ''
      }${
        !isSelectedShopCompleted && selectedShop?.id
          ? `?defaultShop=${selectedShop.id}`
          : ''
      }`;
      return <Navigate to={redirectTo} />;
    }
    return <>{children}</>;
  }
}

AddIntegrationGuard.propTypes = {
  children: PropTypes.node,
};
