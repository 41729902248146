import { makeRequest } from '@api/client';

const getTeamMembersRequest = async () => {
  return await makeRequest(`team_member/members`);
};

const registerTeamMemberRequest = async (body) => {
  return await makeRequest(`team_member/register`, {
    method: 'POST',
    body,
  });
};

const addTeamMemberRequest = async (firstName, lastName, email, role) => {
  return await makeRequest('team_member/add', {
    method: 'POST',
    body: { first_name: firstName, last_name: lastName, email, role },
  });
};

const removeTeamMemberRequest = async (email) => {
  return await makeRequest('team_member/remove', {
    method: 'POST',
    body: { email },
  });
};

const updateTeamMemberRequest = async (id, body) => {
  return await makeRequest(`team_member/modify/${id}/`, {
    method: 'PATCH',
    body,
  });
};

export {
  getTeamMembersRequest,
  registerTeamMemberRequest,
  addTeamMemberRequest,
  removeTeamMemberRequest,
  updateTeamMemberRequest,
};
