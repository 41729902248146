export default function TextField(theme) {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input': {
            fontSize: '14px',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: `${theme.palette.textField.border}`,
              borderRadius: '8px',
            },
            '&:hover fieldset': {
              borderColor: `${theme.palette.textField.focusedBorder}`,
            },
            '&.Mui-focused fieldset': {
              borderColor: `${theme.palette.textField.focusedBorder}`,
              borderWidth: '0.15rem',
            },
          },
        },
      },
    },
  };
}
