import { makeRequest } from '@api/client';

const getCompanyAPI = async () => {
  return await makeRequest('organization', { method: 'GET' });
};

const updateCompanyAPI = async (body) => {
  return await makeRequest('organization', { method: 'PATCH', body });
};

const getCompanyAvatarAPI = async () => {
  return await makeRequest('organization_avatar_decode', {
    method: 'GET',
  });
};

const updateCompanyAvatarAPI = async (body, headers) => {
  return await makeRequest('organization', {
    method: 'PATCH',
    body,
    headers: headers || {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getCountriesAPI = async (params) => {
  return await makeRequest(
    `countries/?${new URLSearchParams(params).toString()}`,
    { method: 'GET' }
  );
};

const getRegionsAPI = async (params) => {
  return await makeRequest(
    `regions/?${new URLSearchParams(params).toString()}`,
    { method: 'GET' }
  );
};

const getCitiesAPI = async (params) => {
  return await makeRequest(
    `cities/?${new URLSearchParams(params).toString()}`,
    { method: 'GET' }
  );
};

const getTimezonesAPI = async () => {
  return await makeRequest('timezones/', { method: 'GET' });
};

const getIndustriesAPI = async () => {
  return await makeRequest('industries/', { method: 'GET' });
};

export {
  getCompanyAPI,
  updateCompanyAPI,
  getCompanyAvatarAPI,
  updateCompanyAvatarAPI,
  getCountriesAPI,
  getRegionsAPI,
  getCitiesAPI,
  getTimezonesAPI,
  getIndustriesAPI,
};
