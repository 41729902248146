import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  ThemeProvider as MUIThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
// hooks
import useThemeMode from '@hooks/useThemeMode';
//
import palette from '@theme/palette';
import typography from '@theme/typography';
import componentsOverride from '@theme/overrides/componentsOverride';

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode } = useThemeMode();

  const themeOptions = useMemo(
    () => ({
      palette: palette[themeMode || 'gold'],
      typography: typography[themeMode || 'gold'],
      shape: { borderRadius: 25 },
      // TODO add more variables: breakpoints/shadows,
    }),
    [themeMode]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
