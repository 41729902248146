import { makeRequest } from '@api/client';

const getCustomerPortalLink = async (body) => {
  return await makeRequest(`billing/portal-link`, {
    method: 'POST',
    body,
  });
};

const getCustomerPortalLinkCheckout = async (body) => {
  return await makeRequest(`billing/portal-link-checkout`, {
    method: 'POST',
    body,
  });
};

const updateCurrentPlanAPI = async (body) => {
  return await makeRequest(`billing/change-plan`, {
    method: 'POST',
    body,
  });
};
const cancelCurrentPlanAPI = async () => {
  return await makeRequest(`billing/cancel-plan`, {
    method: 'POST',
  });
};
const getPlansAPI = async () => {
  return await makeRequest(`billing/plans-tiers`, {
    method: 'GET',
  });
};
const getPlansBracketAPI = async () => {
  return await makeRequest(`billing/all-plans`, {
    method: 'GET',
  });
};

const getPaymentMethodsAPI = async () => {
  return await makeRequest(`billing/payment-methods`, {
    method: 'GET',
  });
};

const addPaymentMethodAPI = async (body) => {
  return await makeRequest(`billing/payment-methods`, {
    method: 'POST',
    body,
  });
};

const deletePaymentMethodAPI = async ({ payment_method_id }) => {
  return await makeRequest(`billing/payment-methods/${payment_method_id}`, {
    method: 'DELETE',
  });
};

const updatePaymentMethodAPI = async ({ body, payment_method_id }) => {
  return await makeRequest(`billing/payment-methods/${payment_method_id}`, {
    method: 'PUT',
    body,
  });
};

const createSubscriptionAPI = async (body) => {
  return await makeRequest(`billing/subscriptions`, {
    method: 'POST',
    body,
  });
};

const getSubscriptionsAPI = async () => {
  return await makeRequest(`billing/subscriptions`, {
    method: 'GET',
  });
};

const updateSubscriptionAPI = async ({ subscription_id, body }) => {
  return await makeRequest(`billing/subscriptions/${subscription_id}`, {
    method: 'PUT',
    body,
  });
};

const cancelSubscriptionAPI = async ({ subscription_id }) => {
  return await makeRequest(`billing/subscriptions/${subscription_id}/cancel`, {
    method: 'POST',
  });
};

const cancelAllSubscriptionAPI = async ({ shopId }) => {
  return await makeRequest(`billing/subscriptions/${shopId}/cancel_all_shop`, {
    method: 'POST',
  });
};

const pauseSubscriptionAPI = async ({ subscription_id, body }) => {
  return await makeRequest(`billing/subscriptions/${subscription_id}/pause`, {
    method: 'POST',
    body,
  });
};

const renewSubscriptionAPI = async ({ subscription_id }) => {
  return await makeRequest(`billing/subscriptions/${subscription_id}/renew`, {
    method: 'POST',
  });
};

const createShopifySubscriptionAPI = async (body) => {
  return await makeRequest(`billing/shopify-subscriptions`, {
    method: 'POST',
    body,
  });
};

export {
  getCustomerPortalLink,
  getCustomerPortalLinkCheckout,
  updateCurrentPlanAPI,
  cancelCurrentPlanAPI,
  getPlansAPI,
  getPlansBracketAPI,
  addPaymentMethodAPI,
  getPaymentMethodsAPI,
  deletePaymentMethodAPI,
  updatePaymentMethodAPI,
  createSubscriptionAPI,
  getSubscriptionsAPI,
  cancelSubscriptionAPI,
  cancelAllSubscriptionAPI,
  updateSubscriptionAPI,
  renewSubscriptionAPI,
  pauseSubscriptionAPI,
  createShopifySubscriptionAPI,
};
