import {
  getCompanyAPI,
  getCountriesAPI,
  getIndustriesAPI,
  getRegionsAPI,
  getCitiesAPI,
  updateCompanyAvatarAPI,
  updateCompanyAPI,
  getCompanyAvatarAPI,
} from '@api/account';
import {
  createAsyncThunk,
  createSlice,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { nomenclatureSnack } from '@utils/nomenclature';

const countriesAdapter = createEntityAdapter({});
const regionsAdapter = createEntityAdapter({});
const citiesAdapter = createEntityAdapter({});

const initialLoadings = {
  loadingCompany: false,
  loadingIndustries: false,
  loadingCountries: false,
  loadingRegions: false,
  loadingCities: false,
  loadingAvatarUpdate: false,
};

const initialValidation = {
  page: 1,
  hasMore: false,
};

export const initialState = {
  uploadingAvatarError: false,
  isAvatarUploaded: false,
  loadings: initialLoadings,
  company: null,
  countries: countriesAdapter.getInitialState([]),
  regions: regionsAdapter.getInitialState([]),
  cities: citiesAdapter.getInitialState([]),
  timezones: [],
  industries: [],
  countriesPagination: initialValidation,
  regionsPagination: initialValidation,
  citiesPagination: initialValidation,
};

// ------------------THUNKS-------------
export const getCompany = createAsyncThunk(
  'account/getCompany',
  async (_, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getCompanyAPI();
    if (isSuccessful) {
      const { data: avatarData } = await getCompanyAvatarAPI();
      data['avatar'] = avatarData.avatar_decoded;
      return data;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const updateCompany = createAsyncThunk(
  'account/updateCompany',
  async (body, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateCompanyAPI(body);
    if (isSuccessful) return { ...data, statusKey };
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const uploadCompanyAvatar = createAsyncThunk(
  'account/uploadCompanyAvatar',
  async ({ avatarFormData, base64Img }, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await updateCompanyAvatarAPI(
      avatarFormData
    );
    if (isSuccessful) {
      return {
        avatar: base64Img,
      };
    }
    return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const getCountries = createAsyncThunk(
  'account/getCountries',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getCountriesAPI(params);
    if (isSuccessful) {
      const response = { ...data, page: params.page };
      if (params?.id) response.dontPaginate = true;
      return response;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getRegions = createAsyncThunk(
  'account/getRegions',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getRegionsAPI(params);
    if (isSuccessful) {
      const response = { ...data, page: params.page };
      if (params?.id) response.dontPaginate = true;
      return response;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
export const getCities = createAsyncThunk(
  'account/getCities',
  async (params, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getCitiesAPI(params);
    if (isSuccessful) {
      const response = { ...data, page: params.page };
      if (params?.id) response.dontPaginate = true;
      return response;
    } else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);
// TODO: Implement on the WRI-2774
// export const getTimezones = createAsyncThunk(
//   'account/getTimezones',
//   async (, thunkAPI) => {
//     const { data, isSuccessful, statusKey } = await getTimezonesAPI();
//     if (isSuccessful) return data;
//     else return thunkAPI.rejectWithValue({ ...data, statusKey });
//   }
// );
export const getIndustries = createAsyncThunk(
  'account/getIndustries',
  async (_, thunkAPI) => {
    const { data, isSuccessful, statusKey } = await getIndustriesAPI();
    if (isSuccessful) return data;
    else return thunkAPI.rejectWithValue({ ...data, statusKey });
  }
);

export const sharedExtraReducers = (builder) => {
  builder
    .addCase(getCompany.pending, (state) => {
      state.loadings = { ...state.loadings, loadingCompany: true };
    })
    .addCase(getCompany.fulfilled, (state, action) => {
      state.company = action.payload;
      state.loadings = { ...state.loadings, loadingCompany: false };
    })
    .addCase(getCompany.rejected, (state) => {
      state.loadings = { ...state.loadings, loadingCompany: false };
    });

  builder
    .addCase(updateCompany.pending, (state) => {
      state.loadings = { ...state.loadings, loadingCompany: true };
    })
    .addCase(updateCompany.fulfilled, (state, action) => {
      state.company = action.payload;
      state.loadings = { ...state.loadings, loadingCompany: false };
      nomenclatureSnack({
        type: 'success',
        message: action?.payload?.statusKey,
      });
    })
    .addCase(updateCompany.rejected, (state, action) => {
      state.loadings = { ...state.loadings, loadingCompany: false };
      nomenclatureSnack({
        type: 'error',
        message: action?.payload?.statusKey,
      });
    });

  builder
    .addCase(uploadCompanyAvatar.pending, (state) => {
      state.loadings = { ...state.loadings, loadingAvatarUpdate: true };
      state.uploadingAvatarError = false;
      state.isAvatarUploaded = false;
    })
    .addCase(uploadCompanyAvatar.fulfilled, (state, action) => {
      const { avatar } = action.payload;
      state.loadings = { ...state.loadings, loadingAvatarUpdate: false };
      state.uploadingAvatarError = false;
      state.isAvatarUploaded = false;
      state.company.avatar = avatar;
    })
    .addCase(uploadCompanyAvatar.rejected, (state) => {
      state.loadings = { ...state.loadings, loadingAvatarUpdate: false };
      state.uploadingAvatarError = false;
      state.isAvatarUploaded = false;
    });

  builder
    .addCase(getIndustries.pending, (state) => {
      state.loadings = { ...state.loadings, loadingIndustries: true };
    })
    .addCase(getIndustries.fulfilled, (state, action) => {
      state.industries = action.payload;
      state.loadings = { ...state.loadings, loadingIndustries: false };
    })
    .addCase(getIndustries.rejected, (state) => {
      state.loadings = { ...state.loadings, loadingIndustries: false };
    });

  builder
    .addCase(getCountries.pending, (state) => {
      state.loadings = { ...state.loadings, loadingCountries: true };
    })
    .addCase(getCountries.fulfilled, (state, action) => {
      const { results, count, page, dontPaginate } = action.payload;
      if (page === 1) countriesAdapter.setAll(state.countries, results);
      else countriesAdapter.upsertMany(state.countries, results);
      if (!dontPaginate) {
        state.countriesPagination = {
          page,
          hasMore: count > state.countries.ids.length,
        };
      }
      state.loadings = { ...state.loadings, loadingCountries: false };
    })
    .addCase(getCountries.rejected, (state) => {
      state.loadings = { ...state.loadings, loadingCountries: false };
    });

  builder
    .addCase(getRegions.pending, (state) => {
      state.loadings = { ...state.loadings, loadingRegions: true };
    })
    .addCase(getRegions.fulfilled, (state, action) => {
      const { results, count, page, dontPaginate } = action.payload;
      if (page === 1) regionsAdapter.setAll(state.regions, results);
      else regionsAdapter.upsertMany(state.regions, results);
      if (!dontPaginate) {
        state.regionsPagination = {
          page,
          hasMore: count > state.regions.ids.length,
        };
      }
      state.loadings = { ...state.loadings, loadingRegions: false };
    })
    .addCase(getRegions.rejected, (state) => {
      state.loadings = { ...state.loadings, loadingRegions: false };
    });

  builder
    .addCase(getCities.pending, (state) => {
      state.loadings = { ...state.loadings, loadingCities: true };
    })
    .addCase(getCities.fulfilled, (state, action) => {
      const { results, count, page, dontPaginate } = action.payload;
      if (page === 1) citiesAdapter.setAll(state.cities, results);
      else citiesAdapter.upsertMany(state.cities, results);
      if (!dontPaginate) {
        state.citiesPagination = {
          page,
          hasMore: count > state.cities.ids.length,
        };
      }
      state.loadings = { ...state.loadings, loadingCities: false };
    })
    .addCase(getCities.rejected, (state) => {
      state.loadings = { ...state.loadings, loadingCities: false };
    });

  // builder
  //   .addCase(getTimezones.pending, (state) => {
  //     state.loadings = { ...state.loadings, loadingTimezones: true };
  //   })
  //   .addCase(getTimezones.fulfilled, (state, action) => {
  //     state.timezones = action.payload;
  //     state.loadings = { ...state.loadings, loadingTimezones: false };
  //   })
  //   .addCase(getTimezones.rejected, (state) => {
  //     state.loadings = { ...state.loadings, loadingTimezones: false };
  //   });
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  extraReducers: sharedExtraReducers,
  reducers: {
    setAvatarBaseUrl: (state, action) => {
      const baseUrl = action.payload;
      const previewUrl = baseUrl.meta.previewUrl;
      state.company.avatar = previewUrl;
      state.isAvatarUploaded = true;
    },
  },
});

// ------------------EXPORT REDUCERS-------------
export const { setAvatarBaseUrl } = accountSlice.actions;
export default accountSlice.reducer;

// ------------------SELECTORS-------------
export const selectAccount = (state) => state.account;
export const selectLoadings = (state) => state.account.loadings;
export const { selectAll: selectCountries } = countriesAdapter.getSelectors(
  (state) => state.account.countries
);
export const { selectAll: selectRegions } = regionsAdapter.getSelectors(
  (state) => state.account.regions
);
export const { selectAll: selectCities } = citiesAdapter.getSelectors(
  (state) => state.account.cities
);
export const selectTimezones = (state) => state.account.timezones;
export const selectIndustries = (state) => state.account.industries;
