// @import dependencies
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

// @import paths
import { authPaths } from '@routes/paths';

// @import Reducers
import { selectUserData } from '@redux/slices/user';
import { selectedSharedShop } from '@redux/slices/sharedJobs';
import {
  loadingPublicShop,
  loadingPublicShopPagination,
  selectShopsPagination,
} from '@redux/slices/dashboard';

export default function PlanGuard({ children }) {
  const { pathname } = useLocation();
  const [loaded, setLoaded] = useState(false);
  const { user, loading } = useSelector(selectUserData);
  const selectedShop = useSelector(selectedSharedShop);
  const loadingShop = useSelector(loadingPublicShop);
  const loadingShopPagination = useSelector(loadingPublicShopPagination);
  const shopsPagination = useSelector(selectShopsPagination);

  const avoidRoutes = [
    authPaths.signUpPlan,
    authPaths.planOverview,
    authPaths.billingOverview,
    authPaths.reusableComponents,
    authPaths.billingRedirection,
    authPaths.onboarding,
  ];
  useEffect(() => {
    if (!loading && user?.id && !loadingShop && !loadingShopPagination)
      setLoaded(true);
  }, [loading, user, loadingShop, loadingShopPagination, shopsPagination]);

  if (pathname === authPaths.billingRedirection) return <>{children}</>;

  if (loaded && user?.id) {
    if (
      ((selectedShop?.id && !selectedShop.main_subscription_id) ||
        !shopsPagination.list?.length) &&
      !avoidRoutes.includes(pathname) &&
      !user.review_admin
    ) {
      return <Navigate to={authPaths.onboarding} />;
    }
    return <>{children}</>;
  }
}

PlanGuard.propTypes = {
  children: PropTypes.node,
};
