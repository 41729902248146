import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
// @import Reducers
import { confirmEmail } from '@redux/slices/auth';
import { selectUserData, setUserDetails } from '@redux/slices/user';
// @import Utils
import { formPage } from '@pages/auth/login/utils';
// @import Hooks
import useHasPermission from '@hooks/useHasPermission';

EmailVerificationGuard.propTypes = {
  children: PropTypes.node,
};

export default function EmailVerificationGuard({ children }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const loadingVerify = useRef(true);

  const [searchParams] = useSearchParams();

  const { loading } = useSelector(selectUserData);
  const { permissions } = useHasPermission('isEmailVerified');

  const environment = import.meta.env.VITE_ENV;

  useEffect(() => {
    validateConfirmEmail();
  }, [pathname, loading, permissions]);

  const validateConfirmEmail = async () => {
    const verifyToken = searchParams.get('verifyEmail');
    if (verifyToken && loadingVerify.current) {
      loadingVerify.current = false;
      try {
        const confirmData = await dispatch(confirmEmail(verifyToken)).unwrap();
        if (confirmData?.isSuccessful) {
          dispatch(
            setUserDetails({
              user: {
                is_email_verified: confirmData.is_email_verified,
              },
            })
          );
          if (environment === 'production') {
            TagManager.dataLayer({
              dataLayer: {
                event: 'email_confirmation',
                email_confirmed: confirmData?.email,
              },
            });
          }
        }
      } catch {
      } finally {
        window.history.replaceState(null, '', `/${formPage}`);
      }
    }
  };

  return children;
}
