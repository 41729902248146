export default function Switch(theme) {
  return {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 40,
          height: 21,
          padding: 0,
          display: 'flex',
          '&:active': {
            '& .MuiSwitch-thumb': {
              width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
              transform: 'translateX(9px)',
            },
          },
          '& .MuiSwitch-switchBase': {
            padding: 2,
            marginTop: '1px',
            '&.Mui-checked': {
              transform: 'translateX(20px)',
              color: 'transparent',
              backgroundColor: 'transparent',
              '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.switch.active,
                border: `none`,
              },
              '& .MuiSwitch-thumb': {
                backgroundColor: 'white',
                border: `none`,
              },
            },
          },
          '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 15,
            height: 15,
            backgroundColor: theme.palette.common.white,
            transition: theme.transitions.create(['width'], {
              duration: 200,
            }),
            '&::before': {
              color: 'white !important',
            },
            '&::after': {
              color: 'black !important',
            },
          },
          '& .MuiSwitch-track': {
            borderRadius: 10,
            opacity: 1,
            backgroundColor: theme.palette.switch.inactive,
          },
        },
      },
    },
  };
}
