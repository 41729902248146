import { makeRequest } from '@api/client';

const getDashboardDataAPI = async (body) => {
  return await makeRequest(
    `public-shop/?page=${body?.page || 1}&page_size=${body?.pageSize || 50}`,
    {
      method: 'GET',
    }
  );
};

const getDashboardDataByIdAPI = async (id) => {
  return await makeRequest(`public-shop/${id}/`, {
    method: 'GET',
  });
};

const getDashboardMetadataByIdAPI = async (id) => {
  return await makeRequest(`public-shop/${id}/metadata`, {
    method: 'GET',
  });
};

const getDashboardPerformanceCardsByIdAPI = async ({ id, params = {} }) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(`dashboard/performance/cards/${id}?${queryParams}`, {
    method: 'GET',
  });
};

const getDashboardPerformanceLineGraphicByIdAPI = async ({
  id,
  params = {},
}) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `dashboard/performance/line-graphic/${id}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const getGoogleSearchConsoleAuthAPI = async (params) => {
  return await makeRequest(
    `google-search-console/authorization/${params || ''}`,
    {
      method: 'GET',
    }
  );
};

const addPublicShopAPI = async (body) => {
  return await makeRequest(`public-shop/`, {
    method: 'POST',
    body,
  });
};

const getDashboardOverviewDataByIdAPI = async (id) => {
  return await makeRequest(`performance/shop/${id}`, {
    method: 'GET',
  });
};

const getDashboardProductPerformanceAPI = async (id) => {
  return await makeRequest(`performance/product/${id}`, {
    method: 'GET',
  });
};

const getDashboardOptimizationPerformanceAPI = async (id) => {
  return await makeRequest(`performance/push/${id}`, {
    method: 'GET',
  });
};

const getDashboardOptimizationProductsAPI = async (body) => {
  return await makeRequest(
    `pushed-products/${body?.id}?page=${body?.page || 1}&page_size=${
      body?.pageSize || 10
    }`,
    {
      method: 'GET',
    }
  );
};

const getDashboardProductTableAPI = async (params) => {
  const urlParams = new URLSearchParams({
    page: params?.page || 1,
    page_size: params?.pageSize || 10,
    interval: params?.interval || '1m',
    metric_type: params?.metric_type || 'sessions',
    optimized: params?.optimized || false,
    is_demo: params?.is_demo || false,
  });
  if (params?.fixed_product)
    urlParams.append('fixed_product', params.fixed_product);

  return await makeRequest(
    `dashboard/performance/products-table/${params.id}?${urlParams.toString()}`,
    {
      method: 'GET',
    }
  );
};

const generateDashboardProductTableCSVAPI = async ({ id, ...params }) => {
  return await makeRequest(`dashboard/performance/products-table-all/${id}`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

const getDashboardTopPerformersAPI = async ({ id, params = {} }) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `dashboard/top-performance/products/${id}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const setFullAutoApprovalAPI = async ({ id, body = {} }) => {
  return await makeRequest(`public-shop/full-auto-approval/${id}`, {
    method: 'PATCH',
    body,
  });
};

const getAdminTableDataAPI = async (params) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(`dashboard/admin-table?${queryParams}`, {
    method: 'GET',
  });
};

const getAdminOverviewDataAPI = async () => {
  return await makeRequest(`admin/dashboard-json/`, {
    method: 'GET',
  });
};

const getAdminHistoricalDataAPI = async () => {
  return await makeRequest(`admin/dashboard-historical-json/`, {
    method: 'GET',
  });
};

const getShopPlanDetailsAPI = async (shopId, params) => {
  const queryParams = new URLSearchParams(params).toString();
  return await makeRequest(
    `public-shop/plan-details/${shopId}?${queryParams}`,
    {
      method: 'GET',
    }
  );
};

const getShopUsageStatisticsAPI = async (shopId) => {
  return await makeRequest(`account/statistics/usage/${shopId}`, {
    method: 'GET',
  });
};

const getShopBillingStatisticsAPI = async (shopId) => {
  return await makeRequest(`account/billing/snapshopt/list/${shopId}`, {
    method: 'GET',
  });
};

export {
  getDashboardDataAPI,
  addPublicShopAPI,
  getDashboardDataByIdAPI,
  getGoogleSearchConsoleAuthAPI,
  getDashboardOverviewDataByIdAPI,
  getDashboardProductPerformanceAPI,
  getDashboardOptimizationPerformanceAPI,
  getDashboardOptimizationProductsAPI,
  getDashboardPerformanceCardsByIdAPI,
  getDashboardPerformanceLineGraphicByIdAPI,
  getDashboardMetadataByIdAPI,
  getDashboardProductTableAPI,
  generateDashboardProductTableCSVAPI,
  getDashboardTopPerformersAPI,
  setFullAutoApprovalAPI,
  getAdminTableDataAPI,
  getAdminOverviewDataAPI,
  getAdminHistoricalDataAPI,
  getShopPlanDetailsAPI,
  getShopUsageStatisticsAPI,
  getShopBillingStatisticsAPI,
};
